import { Box, Divider } from "@mui/material";
import { amber, green, grey, orange } from "@mui/material/colors";

interface ExecutionPlanWorkloadCellProps {
    workloadPercentage?: number;
}

export function ExecutionPlanWorkloadCell({ workloadPercentage }: ExecutionPlanWorkloadCellProps) {
    const SEGMENTS_COUNT = 7;

    if (workloadPercentage && (workloadPercentage < 0 || workloadPercentage > 100)) {
        throw new Error(`workloadPercentage value ${workloadPercentage} out of range (0-100)`);
    }

    const getChartColor = (): string => {
        if (workloadPercentage === undefined) {
            return 'transparent';
        } else if (workloadPercentage < 33) {
            return green['A700'];
        } else if (workloadPercentage >= 33 && workloadPercentage < 66) {
            return orange[300];
        }

        return amber[900];
    };

    return (
        <Box height='100%' width='100%' position='relative'>
            {Array.from({ length: SEGMENTS_COUNT }, (_, num) => num).map(segmentId =>
                <Divider key={segmentId} sx={{ height: `${100 / SEGMENTS_COUNT}%`, borderColor: grey[200] }} />
            )}
            <Box
                sx={{
                    background: `linear-gradient(0deg, ${getChartColor()} ${workloadPercentage ?? 0}%, transparent 0%)`,
                    position: 'absolute',
                    bottom: '0',
                    height: '100%',
                    width: '100%'
                }}
            />
        </Box>
    );
}