import { Grid } from "@mui/material";
import { InfoCard } from "../../../../../components/infoCard/InfoCard";
import { InfoCardTextItem } from "../../../../../components/infoCard/InfoCardTextItem";

export function AssuranceTaskMethodInfoCard() {
    return (
        <InfoCard title="Assurance method info" description="Information about assurance method">
            <Grid container>
                <Grid item md={12}>
                    <InfoCardTextItem label="Description" text="" />
                </Grid>
            </Grid>
        </InfoCard>
    );
}
