import { SvgIconComponent } from "@mui/icons-material";
import {
    Box,
    Button,
    ClickAwayListener,
    Grow,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    SvgIcon,
    Typography
} from "@mui/material";
import { useRef, useState } from "react";

interface DropdownMenuProps {
    text: string;
    StartIcon: SvgIconComponent;
    items: Array<DropdownMenuItemProps>;
}

export function DropdownMenu({ text, StartIcon, items }: DropdownMenuProps) {
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLButtonElement | null>(null);
    const [buttonActive, setButtonActive] = useState<boolean>(false);

    const toggleHover = () => {
        setButtonActive(!buttonActive);
    };
    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const decorateWithHandleClose = (func: () => void) => {
        return function () {
            func();
            handleClose();
        };
    };

    return (
        <>
            <Button ref={anchor}
                    variant='contained'
                    size="small"
                    startIcon={
                        <SvgIcon
                            component={StartIcon}
                            inheritViewBox
                        />
                    }
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}
                    onClick={handleClick}>
                {text}
            </Button>
            <Popper
                open={open}
                anchorEl={anchor.current}
                placement='bottom-start'
                sx={{ zIndex: 10000 }}
                transition
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                        <Paper sx={{ minWidth: 150 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList sx={{ py: 0 }}>
                                        {items.map((item: DropdownMenuItemProps) =>
                                            <DropdownMenuItem key={item.text} {...item} onClick={decorateWithHandleClose(item.onClick)} />
                                        )}
                                    </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

interface DropdownMenuItemProps {
    text: string;
    Icon: SvgIconComponent;
    onClick: () => void;
}

function DropdownMenuItem({ text, Icon, onClick }: DropdownMenuItemProps) {
    return (
        <MenuItem onClick={onClick} sx={{ p: 1 }}>
            <ListItemIcon sx={{ minWidth: 28 }}>
                <SvgIcon component={Icon} inheritViewBox />
            </ListItemIcon>
            <ListItemText primary={text} primaryTypographyProps={{ variant:'caption', color:'text.primary'}} />
        </MenuItem>
    );
}