import { Stack } from "@mui/material";
import { ContractTermDto } from "../PolicyMatrixModel";
import { Tag } from "./Tag";

interface ContractTermsCellProps {
    contractTerms: ContractTermDto[];
}

export function ContractTermsCell({ contractTerms }: ContractTermsCellProps) {
    return (
        <Stack direction="row" spacing={1.25}>
            {contractTerms.map(
                (contractTerm) => <Tag
                    key={contractTerm.name}
                    name={contractTerm.name}
                    color={contractTerm.color}
                />
            )}
        </Stack>
    );
}