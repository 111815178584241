import { AddCircleOutlineRounded, SvgIconComponent } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DropdownMenu } from "../../../components/menu/DropdownMenu";
import { MenuDef } from "../../../components/menu/MenuDefinition";
import { ReactComponent as AssuranceGroupIcon } from "../../../icons/assurance_group.svg";
import { ReactComponent as AssuranceMethodIcon } from "../../../icons/assurance_method.svg";

export function AssuranceActionsButtons() {
    const navigate = useNavigate();

    const menu: MenuDef =
        {
            text: 'Add New',
            StartIcon: AddCircleOutlineRounded,
            items: [
                {
                    text: 'Assurance Group', Icon: AssuranceGroupIcon as SvgIconComponent, onClick: () => {
                        navigate(`/assurancegroups/add/`);
                    }
                },
                {
                    text: 'Assurance Method', Icon: AssuranceMethodIcon as SvgIconComponent, onClick: () => {
                        navigate(`/assurancemethods/add`);
                    }
                }
            ]
        };

    return (
        <Stack direction={{ xs: 'column', sm: 'row' }}
               spacing={{ xs: 1, sm: 2, md: 1 }}
               justifyContent={{ xs: "flex-start", md: "flex-start" }}>
            <DropdownMenu key={menu.text} {...menu} />
        </Stack>
    );
}