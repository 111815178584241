import { AssuranceGroupElementDto } from "../../GroupsModel";
import { AssuranceGroupInputForm } from "./AssuranceGroupInputForm";
import { AssuranceGroupInputFormInputValues } from "./AssuranceGroupInputFormModel";

export function AddAssuranceGroupPage() {

    const initialData: AssuranceGroupInputFormInputValues = {
        name: "",
        shortName: "",
        shortDescription: "",
        description: null,
        elements: [] as AssuranceGroupElementDto[]
    };

    function addAssuranceGroupAction(formData: AssuranceGroupInputFormInputValues) {
        console.log(formData);
    }

    const onCancelOperation = () => {
    };

    return (
        <AssuranceGroupInputForm
            onSubmitOperation={addAssuranceGroupAction}
            onCancelOperation={onCancelOperation}
            initialData={initialData}
        />
    );
}