import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AccordionWrapper } from "../../../../components/AccordionWrapper";
import { AssuranceMethodInputValues, toAssuranceMethodModel } from "./AssuranceMethodInputFormModel";
import { AssuranceCostsInput } from "./components/AssuranceCostsInput";
import { AssuranceMethodSettingsInput } from "./components/AssuranceMethodSettingsInput";
import { ExecutionInput } from "./components/ExecutionInput";
import { InformationConfidenceInput } from "./components/InformationConfidenceInput";
import { MainInformationInput } from "./components/MainInformationInput";
import { MethodOwnerInput } from "./components/MethodOwnerInput";

export interface AssuranceMethodInputFormProps {
    initialData: AssuranceMethodInputValues;
}

export function AssuranceMethodInputForm({ initialData }: AssuranceMethodInputFormProps) {

    const methods = useForm<AssuranceMethodInputValues>({
        mode: "onBlur",
        defaultValues: initialData,
    });

    const {
        handleSubmit,
        formState: { isDirty, isValid },
    } = methods;

    const onSubmit: SubmitHandler<AssuranceMethodInputValues> = (formData: AssuranceMethodInputValues) => { // TODO: implement it
        console.log(toAssuranceMethodModel(formData));
    };

    return (
        <Container>
            <FormProvider {...methods}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Stack sx={{ rowGap: 2 }}>

                        <Typography>* required fields</Typography>

                        <AccordionWrapper summary="Assurance Method Settings">
                            <AssuranceMethodSettingsInput />
                        </AccordionWrapper>

                        <AccordionWrapper summary="Main Information">
                            <MainInformationInput />
                        </AccordionWrapper>

                        <AccordionWrapper summary="Method Owner">
                            <MethodOwnerInput />
                        </AccordionWrapper>

                        <AccordionWrapper summary="Execution">
                            <ExecutionInput />
                        </AccordionWrapper>

                        <AccordionWrapper summary="Information Confidence">
                            <InformationConfidenceInput />
                        </AccordionWrapper>

                        <AccordionWrapper summary="Assurance Costs">
                            <AssuranceCostsInput />
                        </AccordionWrapper>

                        {/*TODO: Use a separately implemented component containing the following form action buttons*/}
                        <Grid container rowSpacing={2} columnSpacing={3} sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!isDirty || !isValid}
                                    sx={{ width: "100%" }}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ width: "100%" }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            </FormProvider>
        </Container>
    );
}