import { AssuranceMethodCategoryDto, AssuranceMethodExecutionFrequencyDto } from "../../../AssuranceMethodsModel";
import { AssuranceMethodInputForm } from "../AssuranceMethodInputForm";
import {
    ApprovalRequestOption,
    AssuranceMethodInputValues,
    AssuranceMethodType,
    Cost,
    Currency,
    Duration,
    EXAMPLE_ASSESSMENTS,
    EXAMPLE_CONTACTS,
    Frequency,
    Period
} from "../AssuranceMethodInputFormModel";

export function EditAssuranceMethodPage() {

    //TODO: Get rid of mocked data
    const initialData: AssuranceMethodInputValues = {
        assuranceMethodType: AssuranceMethodType.AUDIT_INSTRUCTION,
        auditInstruction: {
            recipientName: EXAMPLE_CONTACTS[0],
            defaultAssessor: EXAMPLE_CONTACTS[1],
            assessment: EXAMPLE_ASSESSMENTS[0],
            allowRecipientToEditAssessor: false,
            allowRecipientToAddCompleters: false,
            emailContent: "Managing quality is a crucial process for any business. Ensuring that you have a consistently high-quality" +
                " product translates into satisfied customers and increased sales. In today's marketplace, having a reputation as a" +
                " high-performing, quality business could be a critical differentiator for your company.",
            instructionFile: null,
        },
        approvalRequest: {
            emailContent: "Managing quality is a crucial process for any business. Ensuring that you have a consistently high-quality" +
                " product translates into satisfied customers and increased sales. In today's marketplace, having a reputation as a" +
                " high-performing, quality business could be a critical differentiator for your company.",
            recipientName: EXAMPLE_CONTACTS[0],
            email: "jane.doe@test.com",
            systemID: "",
            approvalRequestOption: ApprovalRequestOption.ACTIVE,
            documentationFile: null,
        },
        rizikonAssessment: {
            assessment: EXAMPLE_ASSESSMENTS[0],
        },
        name: "Supplier Audit - Critical",
        shortName: "Audit",
        shortDescription: "A critical audit that is extremely important to us.",
        fullDescription: "Managing quality is a crucial process for any business. Ensuring that you have a consistently high-quality" +
            " product or service translates into satisfied customers, increased sales ultimately more dollars in the bank. In today's" +
            " very competitive marketplace, having a reputation as a high-performing, quality business could be a critical" +
            " differentiator for your company.",
        categories: [
            AssuranceMethodCategoryDto.APPLICABLE_TO_SUPPLIER,
            AssuranceMethodCategoryDto.MATERIAL,
            AssuranceMethodCategoryDto.PRODUCT,
            AssuranceMethodCategoryDto.SERVICE,
            AssuranceMethodCategoryDto.DATA,
        ] as AssuranceMethodCategoryDto[],
        ownerName: "David Smith",
        ownerEmail: "david@smith.com",
        ownerInfo: "Managing quality is a crucial process for any business. Ensuring that you have a consistently high-quality" +
            " product translates into satisfied customers and increased sales. In today's marketplace, having a reputation as a" +
            " high-performing, quality business could be a critical differentiator for your company.",
        executionStartDate: new Date(2023, 0, 1),
        executionEndDate: new Date(2024, 11, 31),
        executionFrequency: [
            AssuranceMethodExecutionFrequencyDto.ONCE_ONLY,
            AssuranceMethodExecutionFrequencyDto.CONTINUOUS,
            AssuranceMethodExecutionFrequencyDto.PERIODIC,
            AssuranceMethodExecutionFrequencyDto.PER_CONTRACT,
        ] as AssuranceMethodExecutionFrequencyDto[],
        startTimeBeforeDueDate: { rate: "1", unit: Period.WEEK } as Duration,
        maxExecutionsNumber: { rate: "2", period: Period.MONTH } as Frequency,
        infoConfidenceLevel: 90,
        infoConfidenceHalfLifeTime: { rate: "3", unit: Period.YEAR } as Duration,
        totalCost: { rate: "250000", currency: Currency.GBP } as Cost,
        annualCost: { rate: "50000", currency: Currency.GBP } as Cost,
    };

    return (
        <AssuranceMethodInputForm initialData={initialData} />
    );
}