import { Grid, Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { AssuranceTask } from "../../../components/AssuranceTask";
import { AssuranceTaskDto } from "../../../ExecutionPlanModel";

export const ExecutionPlanDetailedViewColumns: GridColumns = [
    {
        field: 'methodId',
        headerName: 'id',
        hide: true,
    },
    {
        field: 'fullName',
        headerName: 'Assurance Method',
        renderCell: (params: GridRenderCellParams<string>) => (
            <MuiLink component={RouterLink} to={"/"}>{params.value!}</MuiLink>
        ),
        flex: 0.7
    },
    {
        field: 'tasks',
        headerName: '5 May 2022 - Wednesday', // FIXME
        renderCell: (params: GridRenderCellParams<AssuranceTaskDto[]>) => (
            <Grid container>
                {params.value?.map(task => <Grid item><AssuranceTask assuranceTask={task} /></Grid>)}
            </Grid>
        ),
        flex: 5
    },
];