import { Typography } from "@mui/material";

export function HomePage() {
    return (
        <>
            <Typography align="center" variant="h5">
                Rizikon Frontend
            </Typography>
        </>
    );
}