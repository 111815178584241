import { Box, Grid, TextField, Typography } from "@mui/material";
import { Controller, useController, useFormContext } from "react-hook-form";
import { CheckboxGroup, SingleCheckbox } from "../../../../../components/CheckboxGroup";
import { unhandledCase } from "../../../../../util/Common";
import { AssuranceMethodCategoryDto } from "../../../AssuranceMethodsModel";
import { AssuranceMethodInputValues } from "../AssuranceMethodInputFormModel";

function AssuranceMethodCategoryInput() {

    const { control, getValues } = useFormContext<AssuranceMethodInputValues>();

    const { field: { onChange } } = useController({
        name: "categories",
        control: control,
    });

    function getCategoryLabel(category: AssuranceMethodCategoryDto) {
        switch (category) {
            case AssuranceMethodCategoryDto.APPLICABLE_TO_SUPPLIER:
                return "Applicable to supplier";
            case AssuranceMethodCategoryDto.MATERIAL:
                return "Material";
            case AssuranceMethodCategoryDto.PRODUCT:
                return "Product";
            case AssuranceMethodCategoryDto.SERVICE:
                return "Service";
            case AssuranceMethodCategoryDto.DATA:
                return "Data";
            default:
                unhandledCase(category);
        }
    }

    const categories: SingleCheckbox<AssuranceMethodCategoryDto>[] = Object.values(AssuranceMethodCategoryDto)
        .map((category: AssuranceMethodCategoryDto) => ({
            value: category,
            label: getCategoryLabel(category),
            size: "small",
            checked: getValues("categories").includes(category),
            xs: 12,
            sm: 6,
            md: 3,
        }) as SingleCheckbox<AssuranceMethodCategoryDto>);

    return (
        <Box>
            <Typography sx={{ mb: 2 }}>Choose assurance method category</Typography>

            <CheckboxGroup<AssuranceMethodCategoryDto>
                checkboxes={categories}
                onChange={(newState: AssuranceMethodCategoryDto[]) => onChange(newState)}
            />
        </Box>
    );
}

export function MainInformationInput() {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    return (
        <Box>
            <Grid container spacing={{ xs: 4 }} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="name"
                                label="Name"
                                variant="standard"
                                error={!!error}
                                helperText={error ? error.message : "Name of assurance method"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Controller
                        name="shortName"
                        control={control}
                        rules={{ required: "Short name is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="shortName"
                                label="Short name"
                                variant="standard"
                                error={!!error}
                                helperText={error ? error.message : "Name used when on width reduced views"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="shortDescription"
                        control={control}
                        rules={{ required: "Short description is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="shortDescription"
                                label="Short description"
                                variant="standard"
                                error={!!error}
                                helperText={error ? error.message : "Short information about assurance method"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Controller
                        name="fullDescription"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                id="fullDescription"
                                label="Full description"
                                multiline
                                rows={4}
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                helperText="Details about assurance method"
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
            </Grid>
            <AssuranceMethodCategoryInput />
        </Box>
    );
}