import { Stack, Typography } from "@mui/material";
import React from "react";

interface InfoCardItemProps {
    label: string;
    children: React.ReactNode;
}

export function InfoCardItem({label, children}: InfoCardItemProps) {
    return (
        <Stack mb={2}>
            <Typography component="span" variant="caption" sx={{py: 1}}>{label}</Typography>
            {children}
        </Stack>
    );
}