import { Box, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { ExecutionPlanPage } from "../../executionPlan/pages/ExecutionPlanPage";
import { POLICY_MATRIX_MOCK_DATA } from "../../policyMatrix/mockData/PolicyMatrixMockData";
import { PolicyMatrixPage } from "../../policyMatrix/pages/PolicyMatrixPage";

export function CyberPolicyPage() {
    const [currentTabId, setCurrentTabId] = useState<string>("policyMatrix");

    const handleTabChange = (event: React.SyntheticEvent, newTabId: string) => {
        setCurrentTabId(newTabId);
    };

    //TODO: Properly get data from the backend and remove the mocks
    return (
        <Container sx={{ ml: "3em", mt: "6em" }}>
            <Grid container columnSpacing={3} alignItems="left" direction={{ md: "row-reverse" }}>
                <Grid item xs={12} md={12}>
                    <Box sx={{ borderColor: "divider" }}>
                        <Tabs value={currentTabId} onChange={handleTabChange}>
                            <Tab value="performance" label="Performance" disabled={true} />
                            <Tab value="executionPlan" label="Execution Plan" />
                            <Tab value="policyMatrix" label="Policy Matrix" />
                            <Tab value="executiveView" label="Executive View" disabled={true} />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TabPanel currentTabId={currentTabId} tabId="performance">
                        <Typography>Performance</Typography>
                    </TabPanel>
                    <TabPanel currentTabId={currentTabId} tabId="executionPlan">
                        <ExecutionPlanPage />
                    </TabPanel>
                    <TabPanel currentTabId={currentTabId} tabId="policyMatrix">
                        <PolicyMatrixPage policyMatrix={POLICY_MATRIX_MOCK_DATA} />
                    </TabPanel>
                    <TabPanel currentTabId={currentTabId} tabId="executiveView">
                        <Typography>Executive View</Typography>
                    </TabPanel>
                </Grid>
            </Grid>
        </Container>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    tabId: string;
    currentTabId: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, currentTabId, tabId } = props;

    return currentTabId === tabId
        ? <Box sx={{ pt: 3 }}>{children}</Box>
        : null;
}