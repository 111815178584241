import { Box, Stack, SvgIcon, Theme, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { AssuranceMethodExecutionStatusDto } from "../../policyMatrix/PolicyMatrixModel";
import { ReactComponent as StatusCompletedIcon } from "../../../icons/status_completed.svg";
import { ReactComponent as StatusLateIcon } from "../../../icons/status_late.svg";
import { ReactComponent as StatusPlannedIcon } from "../../../icons/status_planned.svg";
import { ReactComponent as StatusStartedIcon } from "../../../icons/status_started.svg";
import { ElementType } from "react";

interface ExecutionPlanCellProps {
    isInPresentTimeColumn?: boolean;
    executionStatusToOrganisationCount?: Map<AssuranceMethodExecutionStatusDto, number>;
}

export function ExecutionPlanStatusCell({ isInPresentTimeColumn, executionStatusToOrganisationCount }: ExecutionPlanCellProps) {
    const theme: Theme = useTheme();
    const methodExecutionStatusColor = theme.assuranceMethodExecutionStatusColor;

    return (
        <Stack width='100%' direction='row' justifyContent='center' spacing={2}>
            <ExecutionPlanCellColumn
                icon={StatusCompletedIcon}
                organisationsCount={executionStatusToOrganisationCount?.get(AssuranceMethodExecutionStatusDto.COMPLETED)}
                textColor={methodExecutionStatusColor.completed}
            />
            <ExecutionPlanCellColumn
                icon={StatusStartedIcon}
                organisationsCount={executionStatusToOrganisationCount?.get(AssuranceMethodExecutionStatusDto.STARTED)}
                textColor={methodExecutionStatusColor.started}
            />
            <ExecutionPlanCellColumn
                icon={StatusPlannedIcon}
                organisationsCount={executionStatusToOrganisationCount?.get(AssuranceMethodExecutionStatusDto.PLANNED)}
                textColor={methodExecutionStatusColor.planned}
            />
            {isInPresentTimeColumn &&
                <ExecutionPlanCellColumn
                    icon={StatusLateIcon}
                    organisationsCount={executionStatusToOrganisationCount?.get(AssuranceMethodExecutionStatusDto.LATE)}
                    textColor={methodExecutionStatusColor.late}
                />
            }
        </Stack>
    );
}

interface ExecutionPlanCellColumnProps {
    icon: ElementType;
    organisationsCount?: number;
    textColor: string;
}

function ExecutionPlanCellColumn({ icon, organisationsCount, textColor }: ExecutionPlanCellColumnProps) {
    return (
        <Box>
            <SvgIcon component={icon} sx={{ fontSize: 18, p: 0.5 }} inheritViewBox />
            <Typography color={textColor} variant='body2' textAlign='center'>
                {organisationsCount ?? '-'}
            </Typography>
        </Box>
    );
}