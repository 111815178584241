import { DataGrid, GridSeparatorIcon } from "@mui/x-data-grid";
import { useMemo } from "react";
import { CheckboxFilterOption } from "../../components/toolbar/CheckboxFilter";
import { ExecutionPlanToolbar } from "../../components/toolbar/ExecutionPlanToolbar";
import {
    AssuranceTaskStatusDto,
    AssuranceTaskTypeDto,
    ExecutionPlanDto,
    ExecutionPlanMethodDto,
    ExecutionPlanStatusRowDto
} from "../../ExecutionPlanModel";
import { ExecutionPlanDetailedViewColumns } from "./components/ExecutionPlanDetailedViewColumns";

interface ExecutionPlanDetailedViewPageProps {
    executionPlan: ExecutionPlanDto;
    onOverallViewClick: () => void;
    onDetailedViewClick: () => void;
}

export function ExecutionPlanDetailedViewPage({executionPlan, onOverallViewClick, onDetailedViewClick}: ExecutionPlanDetailedViewPageProps) {

    // FIXME: temporary data
    const rows: ExecutionPlanMethodDto[] = [
        {
            methodId: 1,
            fullName: "Supplier Audit - Critical",
            tasks: [
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Cross Wind Systems",
                    dueDate: "21 September 2022",
                    type: AssuranceTaskTypeDto.MANUAL
                },
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Uma Systems",
                    dueDate: "21 September 2022",
                    type: AssuranceTaskTypeDto.MANUAL
                },
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Voodoo Inc",
                    dueDate: "21 September 2022",
                    type: AssuranceTaskTypeDto.MANUAL
                },
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Ding-a-ling",
                    dueDate: "21 September 2022",
                    type: AssuranceTaskTypeDto.MANUAL
                },
                {
                    status: AssuranceTaskStatusDto.COMPLETED,
                    target: "Flying Tween Systems",
                    dueDate: "4 May 2022",
                    type: AssuranceTaskTypeDto.MANUAL
                },
            ],
        },
        {
            methodId: 2,
            fullName: "Service Audit - Critical",
            tasks: [
                {
                    status: AssuranceTaskStatusDto.PLANNED,
                    target: "Flying Tween Systems",
                    dueDate: "16 May 2022",
                    type: AssuranceTaskTypeDto.MANUAL
                },
            ],
        },
        {
            methodId: 3,
            fullName: "Authorised Supplier Asmt.",
            tasks: [
                {
                    status: AssuranceTaskStatusDto.PLANNED,
                    target: "Nextdoor Flux",
                    dueDate: "16 May 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Tumba Systems",
                    dueDate: "21 September 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Flying Tween Systems",
                    dueDate: "21 September 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Suction Services",
                    dueDate: "21 September 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
                {
                    status: AssuranceTaskStatusDto.COMPLETED,
                    target: "Pome Services",
                    dueDate: "4 May 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
            ],
        },
        {
            methodId: 4,
            fullName: "Low Impact Supplier Asmt.",
            tasks: [
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Flying Tween Systems International",
                    dueDate: "21 May 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
                {
                    status: AssuranceTaskStatusDto.STARTED,
                    target: "Ding-a-ling",
                    dueDate: "21 May 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
                {
                    status: AssuranceTaskStatusDto.COMPLETED,
                    target: "Cross Wind Systems",
                    dueDate: "4 May 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
                {
                    status: AssuranceTaskStatusDto.COMPLETED,
                    target: "Fresh Air Systems",
                    dueDate: "4 May 2022",
                    type: AssuranceTaskTypeDto.AUTOMATIC
                },
            ],
        },
    ];

    const columns = useMemo(() => ExecutionPlanDetailedViewColumns, []);

    // FIXME: Demo data
    const groupFilterOptions: CheckboxFilterOption[] = [
        { value: "Crit. IT Ser.", label: "Critical IT Service" },
        { value: "Sup. IT Ser.", label: "Supporting IT Service" },
        { value: "Auth. Acc. R. Hol.", label: "Authorised Access Rights Holder" },
        { value: "D. Proc.", label: "Data Processor" },
    ];

    const methodFilterOptions: CheckboxFilterOption[] = executionPlan.statusRows.map((statusRow: ExecutionPlanStatusRowDto) => {
        return { value: statusRow.shortMethodName, label: statusRow.fullMethodName };
    });

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            getRowId={row => row.methodId}
            pageSize={100}
            density={'standard'}
            autoHeight={true}
            autoPageSize={true}
            getRowHeight={() => 'auto'}
            components={{
                ColumnResizeIcon: () => <GridSeparatorIcon viewBox='-11 7 48 10' preserveAspectRatio='none' />,
                Toolbar: ExecutionPlanToolbar,
            }}
            componentsProps={{
                toolbar: {
                    groupFilterOptions,
                    methodFilterOptions,
                    onOverallViewClick,
                    onDetailedViewClick
                },
            }}
            sx={{
                border: 0
            }}
        />
    );
}
