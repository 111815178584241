import ClearIcon from "@mui/icons-material/Clear";
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useController, UseControllerProps, useFormContext } from "react-hook-form";
import { AssuranceMethodInputValues, Cost, Currency } from "../AssuranceMethodInputFormModel";

interface CostInputFieldsProps {
    id: string;
    initCost: Cost | null;
    onChange: (state: Cost | null) => void;
    label: string;
    helperText: string;
}

function CostInputFields({ id, initCost, onChange, label, helperText }: CostInputFieldsProps) {

    const [cost, setCost] = useState<Cost | null>(initCost);

    const onChangeCallback = useRef<(state: Cost | null) => void>(onChange);

    useEffect(() => {
        onChangeCallback.current(
            cost?.rate && cost?.currency
                ? { rate: cost.rate, currency: cost.currency } as Cost
                : null
        );
    }, [cost?.rate, cost?.currency, onChangeCallback]);

    function clearRate() {
        setCost((prevCost: Cost | null) => {
            return { ...prevCost, rate: "" } as Cost;
        });
    }

    function updateRate(newRate: string) {
        setCost((prevCost: Cost | null) => {
            return { ...prevCost, rate: newRate } as Cost;
        });
    }

    function updateCurrency(newCurrency: Currency) {
        setCost((prevCost: Cost | null) => {
            return { ...prevCost, currency: newCurrency } as Cost;
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={3}>
                <TextField
                    id={`${id}-rate`}
                    value={cost?.rate ?? ""}
                    variant="standard"
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    helperText={helperText}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => updateRate(event.target.value)}
                    InputProps={{
                        endAdornment:
                            <IconButton
                                onClick={clearRate}
                                sx={{ visibility: cost?.rate ? "visible" : "hidden" }}
                            >
                                <ClearIcon sx={{ fontSize: "medium" }} />
                            </IconButton>
                    }}
                    sx={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <FormControl
                    variant="standard"
                    sx={{ width: "100%" }}
                >
                    <InputLabel id={`${id}-currencyLabel`}>Currency</InputLabel>
                    <Select
                        id={`${id}-currency`}
                        value={cost?.currency ?? "" as Currency}
                        labelId={`${id}-currencyLabel`}
                        label="Currency"
                        disabled={!cost?.rate}
                        onChange={(event: SelectChangeEvent<Currency>) => updateCurrency(event.target.value as Currency)}
                    >
                        {Object.values(Currency).map((currency: Currency) =>
                            <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                        )}
                    </Select>
                    <FormHelperText>Currency</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
}

type AllowedCostInputNames = "totalCost" | "annualCost";

interface CostInputProps extends Pick<UseControllerProps<AssuranceMethodInputValues, AllowedCostInputNames>, "name"> {
    header: string;
    label: string;
    helperText: string;
}

function CostInput({ name, header, label, helperText }: CostInputProps) {

    const { control, getValues } = useFormContext<AssuranceMethodInputValues>();

    const { field: { onChange } } = useController({
        name: name,
        control: control,
    });

    return (
        <Box>
            <Typography sx={{ mb: 2 }}>{header}</Typography>

            <CostInputFields
                id={name}
                initCost={getValues(name)}
                onChange={(newState: Cost | null) => onChange(newState)}
                label={label}
                helperText={helperText}
            />
        </Box>
    );
}

export function AssuranceCostsInput() {
    return (
        <Stack sx={{ rowGap: 4 }}>
            <CostInput
                name="totalCost"
                header="Total expenditure incurred by a company"
                label="Unit cost"
                helperText="Unit cost"
            />
            <CostInput
                name="annualCost"
                header="Total amount needed each time period"
                label="Annual cost"
                helperText="Annual cost"
            />
        </Stack>
    );
}