import { Grid } from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ReactNode } from "react";
import { InfoCard } from "../../../../../components/infoCard/InfoCard";
import { InfoCardTextItem } from "../../../../../components/infoCard/InfoCardTextItem";
import { InfoCardItem } from "../../../../../components/infoCard/InfoCardItem";

interface GridItemProps {
    children: ReactNode;
}

function GridItem({ children }: GridItemProps) {
    return (
        <Grid item xs={12} md={4}>
            {children}
        </Grid>
    );
};

export function AssuranceTaskDetailsCard() {
    return (
        <InfoCard title="Assurance task details" description="Additional information about assurance task.">
            <Grid container>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Confidence" text="90%" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Date created" text="06/04/2022" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardItem label="Assurance Method Owner">
                        <MuiLink component={RouterLink} to={"/"}>Katharine Berlin</MuiLink>
                    </InfoCardItem>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Confidence half-life" text="26 weeks" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Due date" text="16/05/2022" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardItem label="Owner email">
                        <MuiLink component={RouterLink} to={"/"}>katharineberlin@xyz.com</MuiLink>
                    </InfoCardItem>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Unit cost" text="100.00 GBP" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Lead time" text="1 week" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardItem label="Owner system account">
                        <MuiLink component={RouterLink} to={"/"}>Katharine Berlin</MuiLink>
                    </InfoCardItem>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Annual cost" text="1000.00 GBP" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoCardTextItem label="Frequency" text="Every 1 day" />
                </Grid>
            </Grid>
        </InfoCard>
    );
}
