import { Box, Theme, Typography, useTheme } from "@mui/material";
import { ExecutionPlanHeaderDto } from "../ExecutionPlanModel";

interface ExecutionPlanHeaderProps {
    isInPresentTimeColumn?: boolean;
    header: ExecutionPlanHeaderDto;
}

export function ExecutionPlanHeader({ isInPresentTimeColumn, header }: ExecutionPlanHeaderProps) {
    const theme: Theme = useTheme();
    const textColor: string = isInPresentTimeColumn ? theme.palette.primary.main : theme.palette.text.primary;

    return (
        <Box sx={{
            width: '100%',
            boxShadow: '0rem 0.05rem 0.1rem #00000029',
            border: 1,
            borderColor: 'grey.200',
            borderRadius: 1
        }}>
            <Typography color={textColor} fontWeight='bold' variant='caption' component='div' textAlign='center'>
                {header.upperPart}
            </Typography>
            <Typography color={textColor} variant='caption' component='div' textAlign='center'>
                {header.lowerPart}
            </Typography>
        </Box>
    );
}