import { Typography } from "@mui/material";
import { InfoCardItem } from "./InfoCardItem";

interface InfoCardTextItemProps {
    label: string;
    text: string | number | undefined;
}

export function InfoCardTextItem({label, text}: InfoCardTextItemProps) {

    return (
        <InfoCardItem label={label}>
            <Typography sx={{wordWrap: "break-word"}}>{text || '-'}</Typography>
        </InfoCardItem>
    );
}