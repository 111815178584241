import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import React from 'react';
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import { AddAssuranceGroupPage } from "./features/assuranceGroups/pages/addGroup/AddAssuranceGroupPage";
import { EditAssuranceGroupPage } from "./features/assuranceGroups/pages/addGroup/EditAssuranceGroupPage";
import { AddAssuranceMethodPage } from "./features/assuranceMethods/pages/addAssuranceMethod/pages/AddAssuranceMethodPage";
import { EditAssuranceMethodPage } from "./features/assuranceMethods/pages/addAssuranceMethod/pages/EditAssuranceMethodPage";
import { CyberPolicyPage } from "./features/cyberPolicies/pages/CyberPoliciesPage";
import { AssuranceTaskDetailsViewPage } from "./features/executionPlan/pages/AssuranceTaskDetailsView/AssuranceTaskDetailsViewPage";
import { HomePage } from "./features/home/pages/HomePage";
import { TopNavBar } from "./layout/navigation/topNavBar/TopNavBar";
import { theme } from "./root/theme";

const routes: RouteObject[] = [
    {
        element: <PageLayout />,
        children: [
            { path: "/home", element: <HomePage /> },
            { path: "/cyberpolicies", element: <CyberPolicyPage /> },
            { path: "/assurancemethods/add/", element: <AddAssuranceMethodPage /> },
            { path: "/assurancemethods/edit/", element: <EditAssuranceMethodPage /> },
            { path: "/assurancegroups/add/", element: <AddAssuranceGroupPage /> },
            { path: "/assurancegroups/edit/", element: <EditAssuranceGroupPage /> },
            { path: "/executionplan/taskview/", element: <AssuranceTaskDetailsViewPage /> },
            { path: "/", element: <Navigate to={"/cyberpolicies"} replace /> },
            { path: "*", element: <>Page not found</> },
        ]
    },
];

function App() {

    const renderedRoutes = useRoutes(routes);

    return (
        <ThemeProvider theme={theme}>

            <CssBaseline enableColorScheme />
            <Box sx={{ display: 'flex' }}>
                {renderedRoutes}
            </Box>
        </ThemeProvider>
    );
}

function PageLayout({ children }: any) {
    return (
        <>
            <TopNavBar />
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Box sx={{ mx: 3, mt: "6em", mb: 3 }}>
                    {children}
                    <Outlet />
                </Box>
            </Box>
        </>
    );
}

export { App };