import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Divider,
    FormControlLabel,
    FormGroup,
    Grow,
    Paper,
    Popper,
    Stack,
    SvgIcon,
    Switch,
    TextField,
    TextFieldProps,
    Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useRef, useState } from "react";
import { ReactComponent as LessDetailsIcon } from "../../../../icons/less_details.svg";
import { ReactComponent as MoreDetailsIcon } from "../../../../icons/more_details.svg";
import { CheckboxFilter, CheckboxFilterOption } from "./CheckboxFilter";

interface ExecutionPlanToolbarProps {
    groupFilterOptions: CheckboxFilterOption[];
    methodFilterOptions: CheckboxFilterOption[];
    onOverallViewClick: () => void;
    onDetailedViewClick: () => void;
}

export function ExecutionPlanToolbar({ groupFilterOptions, methodFilterOptions, onOverallViewClick, onDetailedViewClick }: ExecutionPlanToolbarProps) {
    return (
        <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ justifyContent: { xs: "flex-start", sm: "space-between" } }}
        >
            <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ alignItems: "center" }}>
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<SvgIcon component={LessDetailsIcon} inheritViewBox />}
                    color="secondary"
                    onClick={onOverallViewClick}
                >
                    Overall view
                </Button>

                <Button
                    variant="contained"
                    size="small"
                    startIcon={<SvgIcon component={MoreDetailsIcon} inheritViewBox />}
                    color="secondary"
                    onClick={onDetailedViewClick}
                >
                    Detailed view
                </Button>

                <DateScopeInput />

                <CheckboxFilter
                    filterLabel="Assurance methods"
                    checkAllLabel="Show all methods"
                    allOptions={methodFilterOptions}
                    checkedOptionsValues={methodFilterOptions.map((option: CheckboxFilterOption) => option.value)}
                    onChange={() => {
                    }}
                />

                <CheckboxFilter
                    filterLabel="Assurance groups"
                    checkAllLabel="Show all groups"
                    allOptions={groupFilterOptions}
                    checkedOptionsValues={groupFilterOptions.map((option: CheckboxFilterOption) => option.value)}
                    onChange={() => {
                    }}
                />

                <TimelineFilter />
            </Stack>
            <Button size="medium" color="secondary">
                Clear all filters
            </Button>
        </Stack>
    );
}

function DateScopeInput() {

    const [date, setDate] = useState<Date | null>(new Date(2022, 0, 1));

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                views={["year"]} // TODO: Change this "view" based on overall or detailed views
                openTo="year"
                inputFormat="yyyy"
                label="Scope"
                value={date}
                onChange={setDate}
                renderInput={(params: TextFieldProps) =>
                    <TextField
                        {...params}
                        size="small"
                        color="secondary"
                        sx={{ width: "6em" }}
                    />
                }
            />
        </LocalizationProvider>
    );
}

function TimelineFilter() {

    const [open, setOpen] = useState<boolean>(false);

    const anchor = useRef<HTMLButtonElement | null>(null);

    const handleButtonClick = () => {
        setOpen((prevOpen: boolean) => !prevOpen);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                ref={anchor}
                size="small"
                color="secondary"
                startIcon={<SvgIcon component={FilterAltOutlinedIcon} inheritViewBox />}
                onClick={handleButtonClick}
                variant="outlined"
            >
                Filter timeline
            </Button>
            <Popper
                color="secondary"
                open={open}
                anchorEl={anchor.current}
                placement='bottom-start'
                transition
                style={{ width: '20%' }}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                        <Paper sx={{ p: 2 }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <Box>
                                    <Typography sx={{ mb: 1 }}>Filter timeline</Typography>

                                    <FormControlLabel
                                        control={<Switch color="secondary" size="small" defaultChecked />}
                                        label={<Typography variant="caption" color='text.primary'>Assigned to me</Typography>}
                                    />

                                    <DashedDivider />

                                    {/*TODO: Use enum values */}
                                    <FormGroup>
                                        <CheckboxLabeled label="Per event" />
                                        <CheckboxLabeled label="Once" />
                                        <CheckboxLabeled label="Continuous" />
                                        <CheckboxLabeled label="Daily" />
                                    </FormGroup>

                                    <DashedDivider />

                                    {/*TODO: Use enum values */}
                                    <FormGroup>
                                        <CheckboxLabeled label="Completed" />
                                        <CheckboxLabeled label="Started" />
                                        <CheckboxLabeled label="Planned" />
                                        <CheckboxLabeled label="Late" />
                                    </FormGroup>
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

function DashedDivider() {
    return (
        <Divider
            orientation="horizontal"
            flexItem
            sx={{ borderStyle: "dashed", borderBottomWidth: 2, my: 1 }}
        />
    );
}

interface CheckboxLabeledProps {
    label: string;
}

function CheckboxLabeled({ label }: CheckboxLabeledProps) {
    return (
        <FormControlLabel
            control={<Checkbox color="secondary" size="small" defaultChecked />}
            label={<Typography variant="caption" color='text.primary'>{label}</Typography>}
        />
    );
}