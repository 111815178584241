import { UrlFile } from "../../components/fileUploading/FileDropZone";
import {
    ApprovalRequestOption,
    Assessment,
    AssuranceMethodType,
    Contact,
    Cost,
    Duration,
    Frequency
} from "./pages/addAssuranceMethod/AssuranceMethodInputFormModel";

export enum AssuranceMethodCategoryDto {
    APPLICABLE_TO_SUPPLIER = "APPLICABLE_TO_SUPPLIER",
    MATERIAL = "MATERIAL",
    PRODUCT = "PRODUCT",
    SERVICE = "SERVICE",
    DATA = "DATA",
}

export enum AssuranceMethodExecutionFrequencyDto {
    ONCE_ONLY = "ONCE_ONLY",
    CONTINUOUS = "CONTINUOUS",
    PERIODIC = "PERIODIC",
    PER_CONTRACT = "PER_CONTRACT",
}

export interface AssuranceMethodDataDto {
    name: string;
    shortName: string;
    shortDescription: string;
    fullDescription: string;
    categories: AssuranceMethodCategoryDto[];
}

// TODO adjustments might be needed - current model reflects react-hook-form model
export interface AssuranceMethodOwnerDto {
    name: string;
    email: string;
    info: string;
}

export interface AssuranceMethodExecutionDto {
    startDate: Date | null;
    endDate: Date | null;
    frequency: AssuranceMethodExecutionFrequencyDto[];
    startTimeBeforeDueDate: Duration;
    maxExecutionsNumber: Frequency | null;
}

export interface InformationConfidenceDto {
    confidenceLevel: number;
    confidenceHalfLifeTime: Duration | null;
}

export interface AssuranceMethodCostsDto {
    totalCost: Cost | null;
    annualCost: Cost | null;
}

export interface AssuranceMethodBasicInfoDto {
    methodType: AssuranceMethodType;
    data: AssuranceMethodDataDto;
    methodOwner: AssuranceMethodOwnerDto;
    execution: AssuranceMethodExecutionDto;
    informationConfidence: InformationConfidenceDto;
    costs: AssuranceMethodCostsDto;
}

export interface AuditInstructionMethodDto {
    basicInfo: AssuranceMethodBasicInfoDto;
    recipientName: Contact;
    defaultAssessor: Contact | null;
    assessment: Assessment | null;
    allowRecipientToEditAssessor: boolean;
    allowRecipientToAddCompleters: boolean;
    emailContent: string;
    instructionFile: UrlFile | null;
}

export interface ApprovalRequestMethodDto {
    basicInfo: AssuranceMethodBasicInfoDto;
    emailContent: string;
    recipientName: Contact;
    email: string;
    systemID: string;
    approvalRequestOption: ApprovalRequestOption;
    documentationFile: UrlFile | null;
}

export interface RizikonAssessmentMethodDto {
    basicInfo: AssuranceMethodBasicInfoDto;
    assessment: Assessment | null;
}