import { FormControl, Grid, RadioGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { unhandledCase } from "../../../../../util/Common";
import { AssuranceMethodInputValues, AssuranceMethodType } from "../AssuranceMethodInputFormModel";
import { ApprovalRequestInput } from "./approvalRequest/ApprovalRequestInput";
import { AuditInstructionInput } from "./auditInstruction/AuditInstructionInput";
import { RadioInput } from "./RadioInput";
import { RizikonAssessmentInput } from "./RizikonAssessmentInput";

export function AssuranceMethodSettingsInput() {

    const { control, getValues } = useFormContext<AssuranceMethodInputValues>();

    function getAssuranceMethodTypeLabel(methodType: AssuranceMethodType): string {
        switch (methodType) {
            case AssuranceMethodType.AUDIT_INSTRUCTION:
                return "Audit instruction";
            case AssuranceMethodType.APPROVAL_REQUEST:
                return "Approval request";
            case AssuranceMethodType.DARKBEAM_LOOKUP:
                return "Darkbeam lookup";
            case AssuranceMethodType.RIZIKON_ASSESSMENT:
                return "Rizikon assessment";
            default:
                return unhandledCase(methodType);
        }
    }

    return (
        <>
            <Typography sx={{ pb: 2 }}>Choose assurance method type</Typography>
            <Controller
                name="assuranceMethodType"
                control={control}
                render={({ field }) =>
                    <FormControl fullWidth>
                        <RadioGroup {...field}>
                            <Grid container sx={{ pb: 4 }}>
                                {Object.values(AssuranceMethodType).map((methodType: AssuranceMethodType) => (
                                    <Grid item xs={4} sm={4} key={methodType} sx={{ py: 1 }}>
                                        <RadioInput value={methodType} label={getAssuranceMethodTypeLabel(methodType)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <MethodSpecificInput methodType={getValues("assuranceMethodType")} />
                        </RadioGroup>
                    </FormControl>
                }
            />
        </>
    );
}

interface MethodFormProps {
    methodType: AssuranceMethodType;
}

function MethodSpecificInput({ methodType }: MethodFormProps) {

    switch (methodType) {
        case AssuranceMethodType.AUDIT_INSTRUCTION:
            return <AuditInstructionInput />;
        case AssuranceMethodType.APPROVAL_REQUEST:
            return <ApprovalRequestInput />;
        case AssuranceMethodType.DARKBEAM_LOOKUP:
            return <></>;
        case AssuranceMethodType.RIZIKON_ASSESSMENT:
            return <RizikonAssessmentInput />;
        default:
            return unhandledCase(methodType);
    }
}
