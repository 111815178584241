import { Controller, useFormContext } from "react-hook-form";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { AssuranceGroupInputFormInputValues } from "../AssuranceGroupInputFormModel";

export function MainInformationInput() {

    const { control } = useFormContext<AssuranceGroupInputFormInputValues>();

    return (
        <Box>
            <Grid container spacing={{ xs: 4, md: 5 }}>
                <Grid item xs={12} sm={8}>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="name"
                                label="Name"
                                error={!!error}
                                helperText={error ? error.message : "Name of assurance group"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Controller
                        name="shortName"
                        control={control}
                        rules={{ required: "Short name is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="shortName"
                                label="Short name"
                                error={!!error}
                                helperText={error ? error.message : "Name used when on width reduced views"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="shortDescription"
                        control={control}
                        rules={{ required: "Short description is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="shortDescription"
                                label="Short description"
                                error={!!error}
                                helperText={error ? error.message : "Short information about assurance method"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            maxLength: { value: 600, message: "Max. number of letters is 600" }
                        }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                id="description"
                                label="Full description"
                                multiline
                                rows={4}
                                InputLabelProps={{ shrink: true }}
                                error={!!error}
                                helperText={error ? error.message : "Details about assurance method"}
                                FormHelperTextProps={{
                                    sx: { ...(!error && { textAlign: "right", mr: 0 }) }
                                }}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
