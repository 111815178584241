import { FormControl, Grid, RadioGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { unhandledCase } from "../../../../../../util/Common";
import { ApprovalRequestOption, AssuranceMethodInputValues } from "../../AssuranceMethodInputFormModel";
import { RadioInput } from "../RadioInput";

export function ApprovalRequestOptionInput() {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    function getOptionLabel(option: ApprovalRequestOption) {
        switch (option) {
            case ApprovalRequestOption.ACTIVE:
                return "Active";
            case ApprovalRequestOption.INACTIVE:
                return "Inactive";
            case ApprovalRequestOption.PAUSED:
                return "Paused";
            default:
                return unhandledCase(option);
        }
    }

    return (
        <>
            <Typography sx={{ mb: 3 }}>Approval request options</Typography>
            <Controller
                name="approvalRequest.approvalRequestOption"
                control={control}
                render={({ field }) =>
                    <FormControl fullWidth>
                        <RadioGroup row sx={{ px: 2 }} {...field} >
                            <Grid container>
                                {Object.values(ApprovalRequestOption).map((option: ApprovalRequestOption) => (
                                    <Grid item xs={4} sm={3} key={option}>
                                        <RadioInput<ApprovalRequestOption>
                                            value={option}
                                            label={getOptionLabel(option)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                }
            />
        </>
    );
}