import { AssuranceMethodCategoryDto, AssuranceMethodExecutionFrequencyDto } from "../../../AssuranceMethodsModel";
import { AssuranceMethodInputForm } from "../AssuranceMethodInputForm";
import {
    ApprovalRequestOption,
    AssuranceMethodInputValues,
    AssuranceMethodType,
    Duration,
    EXAMPLE_ASSESSMENTS,
    EXAMPLE_CONTACTS,
    Period
} from "../AssuranceMethodInputFormModel";

export function AddAssuranceMethodPage() {

    const initialData: AssuranceMethodInputValues = {
        assuranceMethodType: AssuranceMethodType.AUDIT_INSTRUCTION,
        auditInstruction: {
            recipientName: EXAMPLE_CONTACTS[0],
            defaultAssessor: EXAMPLE_CONTACTS[1],
            assessment: null,
            allowRecipientToEditAssessor: false,
            allowRecipientToAddCompleters: false,
            emailContent: "",
            instructionFile: null,
        },
        approvalRequest: {
            emailContent: "",
            recipientName: EXAMPLE_CONTACTS[0],
            email: "",
            systemID: "",
            approvalRequestOption: ApprovalRequestOption.ACTIVE,
            documentationFile: null,
        },
        rizikonAssessment: {
            assessment: EXAMPLE_ASSESSMENTS[0],
        },
        name: "",
        shortName: "",
        shortDescription: "",
        fullDescription: "",
        categories: [] as AssuranceMethodCategoryDto[],
        ownerName: "",
        ownerEmail: "",
        ownerInfo: "",
        executionStartDate: null,
        executionEndDate: null,
        executionFrequency: [] as AssuranceMethodExecutionFrequencyDto[],
        startTimeBeforeDueDate: { rate: "1", unit: Period.WEEK } as Duration,
        maxExecutionsNumber: null,
        infoConfidenceLevel: 1,
        infoConfidenceHalfLifeTime: null,
        totalCost: null,
        annualCost: null,
    };

    return (
        <AssuranceMethodInputForm initialData={initialData} />
    );
}