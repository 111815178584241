import { Checkbox, CheckboxProps, FormControlLabel, Grid, RegularBreakpoints } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";

export interface SingleCheckbox<T> extends Pick<CheckboxProps, "size" | "checked">, RegularBreakpoints {
    value: T;
    label: string;
}

interface CheckboxGroupProps<T> {
    checkboxes: SingleCheckbox<T>[];
    onChange: (state: T[]) => void;
}

export function CheckboxGroup<T>({ checkboxes, onChange }: CheckboxGroupProps<T>) {

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<T[]>(() => {
        return checkboxes
            .filter(checkbox => checkbox.checked)
            .map(checkbox => checkbox.value);
    });

    const onChangeCallback = useRef<(state: T[]) => void>(onChange);

    useEffect(() => {
        onChangeCallback.current(selectedCheckboxes);
    }, [selectedCheckboxes, onChangeCallback]);

    return (
        <Grid container>
            {checkboxes.map((checkbox: SingleCheckbox<T>, index: number) =>
                <Grid item key={index} {...{ xs: checkbox.xs, sm: checkbox.sm, md: checkbox.md, lg: checkbox.lg, xl: checkbox.xl }}>
                    <FormControlLabel
                        label={checkbox.label}
                        control={
                            <Checkbox
                                size={checkbox.size}
                                value={checkbox.value}
                                checked={selectedCheckboxes.includes(checkbox.value)}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    const value: T = event.target.value as unknown as T;
                                    setSelectedCheckboxes(
                                        selectedCheckboxes.includes(value)
                                            ? selectedCheckboxes.filter((item: T) => item !== value)
                                            : [...selectedCheckboxes, value]
                                    );
                                }}
                            />
                        }
                    />
                </Grid>
            )}
        </Grid>
    );
}