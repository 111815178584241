import { AddCircleOutlineOutlined, DeleteOutlined } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useMemo } from "react";
import { AssuranceGroupElementDto } from "../../../GroupsModel";
import { GroupElementsColumns } from "./GroupElementsColumns";

interface GroupElementsGridProps {
    data: AssuranceGroupElementDto[];
}

export function GroupElementsGrid({ data }: GroupElementsGridProps) {

    const columns = useMemo(() => GroupElementsColumns, []);

    return (
        <Box>
            <Typography sx={{ py: 1 }}>All elements that are part of this group.</Typography>
            <Stack direction="row" spacing={1} sx={{ marginBottom: 1 }}>
                <Button variant="contained" startIcon={<AddCircleOutlineOutlined />} color="primary">Add more</Button>
                <Button variant="contained" startIcon={<DeleteOutlined />} disabled>Remove</Button>
            </Stack>
            <DataGrid
                rows={data!}
                columns={columns}
                getRowId={(row) => row.assuranceGroupElementId}
                sx={{ fontSize: '0.8rem', fontWeight: 200, p: 1 }}
                components={{
                    Toolbar: GridToolbar,
                }}
                pageSize={10}
                density={"standard"}
                autoHeight={true}
                autoPageSize={true}
            />
        </Box>
    );
}
