import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FileUpload } from "../../../../../../components/fileUploading/FileUpload";
import { AssuranceMethodInputValues, Contact, EXAMPLE_CONTACTS } from "../../AssuranceMethodInputFormModel";
import { ApprovalRequestOptionInput } from "./ApprovalRequestOptionInput";

const FILE_SIZE_LIMIT: number = 1048576;

export function ApprovalRequestInput() {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
                <Controller
                    name="approvalRequest.emailContent"
                    control={control}
                    rules={{ required: "Email content is required" }}
                    render={({ field, fieldState: { error } }) =>
                        <TextField
                            {...field}
                            required
                            multiline
                            rows={4}
                            id="emailContent"
                            label="Email content"
                            error={!!error}
                            helperText={error ? error.message : "Approval Request email content"}
                            sx={{ width: "100%", pl: 0 }}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                <Controller
                    name="approvalRequest.recipientName"
                    rules={{ required: "Recipient is required" }}
                    render={({ field: { onChange, ...rest }, fieldState: { error } }) =>
                        <Autocomplete
                            {...rest}
                            id="approvalRequest.recipientName"
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required
                                    label="Recipient name"
                                    error={!!error}
                                    helperText={error ? error.message : "Approval Request recipient name"}
                                />
                            }
                            popupIcon={<SearchIcon />}
                            options={EXAMPLE_CONTACTS}
                            getOptionLabel={(contact: Contact) => contact.firstName + " " + contact.lastName}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(_, contact: Contact | null) => onChange(contact)}
                            sx={{
                                pl: 0, mr: 2,
                                "& .MuiAutocomplete-popupIndicator": {
                                    transform: "none"
                                }
                            }}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={3}>
                        <Controller
                            name="approvalRequest.email"
                            rules={{ pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Invalid email address" } }}
                            render={({ field, fieldState: { error } }) =>
                                <TextField
                                    {...field}
                                    id="approvalRequest.email"
                                    label="Email"
                                    error={!!error}
                                    helperText={error ? error.message : "Approval Request recipient email address"}
                                    sx={{ width: "100%" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={1} textAlign="center">
                        <Typography color={"text.secondary"}>or</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            name="approvalRequest.systemID"
                            rules={{ required: "System ID is required" }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    id="approvalRequest.systemID"
                                    label="System ID"
                                    helperText={"Approval Request recipient system ID"}
                                    sx={{ width: "100%", ml: 0 }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ApprovalRequestOptionInput />
            </Grid>
            <Grid item xs={12} md={9}>
                <Controller
                    name="approvalRequest.documentationFile"
                    control={control}
                    render={({ field: { onChange } }) =>
                        <FileUpload
                            // TODO: define accepted files
                            acceptFileTypes={{ 'text/*': [], "application/pdf": [] }}
                            onChange={onChange}
                            fileSizeLimit={FILE_SIZE_LIMIT}
                            label="Documentation"
                            hintMessage="Approval Request documentation"
                        />
                    }
                />
            </Grid>
        </Grid>
    );
}