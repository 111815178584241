import { AssuranceMethodExecutionStatusDto } from "../policyMatrix/PolicyMatrixModel";

export interface ExecutionPlanDto {
    columnDefinitions: ExecutionPlanColumnDefinitionDto[];
    statusRows: ExecutionPlanStatusRowDto[];
}

export interface ExecutionPlanColumnDefinitionDto {
    header: ExecutionPlanHeaderDto;
    workloadPercentage?: number;
}

export interface ExecutionPlanHeaderDto {
    upperPart: string;
    lowerPart: string;
}

export interface ExecutionPlanStatusRowDto {
    methodId: number;
    fullMethodName: string;
    shortMethodName: string;
    cells?: ExecutionPlanStatusCellDto[];
}

export interface ExecutionPlanStatusCellDto {
    header: ExecutionPlanHeaderDto;
    executionStatusToOrganisationCount: Map<AssuranceMethodExecutionStatusDto, number>;
}

export interface ExecutionPlanMethodDto {
    methodId: number;
    fullName: string;
    tasks: AssuranceTaskDto[];
}

export interface AssuranceTaskDto {
    status: AssuranceTaskStatusDto;
    target: string;
    dueDate: string;
    type: AssuranceTaskTypeDto;
}

export enum AssuranceTaskStatusDto {
    PLANNED = 'PLANNED',
    STARTED = 'STARTED',
    COMPLETED = 'COMPLETED',
}

export enum AssuranceTaskTypeDto {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
}
