import { AssuranceMethodExecutionStatusDto } from "../../policyMatrix/PolicyMatrixModel";
import { ExecutionPlanColumnDefinitionDto, ExecutionPlanDto, ExecutionPlanStatusRowDto } from "../ExecutionPlanModel";

const columnDefinitionsYearly: ExecutionPlanColumnDefinitionDto[] = [
    {
        header: { upperPart: 'January', lowerPart: '2022' },
        workloadPercentage: 10
    },
    {
        header: { upperPart: 'February', lowerPart: '2022' },
        workloadPercentage: 55,
    },
    {
        header: { upperPart: 'March', lowerPart: '2022' },
        workloadPercentage: 30,
    },
    {
        header: { upperPart: 'April', lowerPart: '2022' },
        workloadPercentage: 99,
    },
    { header: { upperPart: 'May', lowerPart: '2022' } },
    { header: { upperPart: 'June', lowerPart: '2022' } },
    {
        header: { upperPart: 'July', lowerPart: '2022' },
        workloadPercentage: 50,
    },
    { header: { upperPart: 'August', lowerPart: '2022' } },
    { header: { upperPart: 'September', lowerPart: '2022' } },
    {
        header: { upperPart: 'October', lowerPart: '2022' },
        workloadPercentage: 67.8,
    },
    {
        header: { upperPart: 'November', lowerPart: '2022' },
        workloadPercentage: 17.5,
    },
    { header: { upperPart: 'December', lowerPart: '2022' } },
];

const statusRows: ExecutionPlanStatusRowDto[] = [
    {
        methodId: 1,
        fullMethodName: 'Supplier Audit-Critical',
        shortMethodName: 'Sup. A. Crit.',
        cells: [
            {
                header: { upperPart: 'January', lowerPart: '2022' },
                executionStatusToOrganisationCount: new Map<AssuranceMethodExecutionStatusDto, number>()
                    .set(AssuranceMethodExecutionStatusDto.COMPLETED, 9)
                    .set(AssuranceMethodExecutionStatusDto.STARTED, 10)
                    .set(AssuranceMethodExecutionStatusDto.PLANNED, 10)
                    .set(AssuranceMethodExecutionStatusDto.LATE, 1)
            },
            {
                header: { upperPart: 'February', lowerPart: '2022' },
                executionStatusToOrganisationCount: new Map<AssuranceMethodExecutionStatusDto, number>()
                    .set(AssuranceMethodExecutionStatusDto.PLANNED, 16)
                    .set(AssuranceMethodExecutionStatusDto.LATE, 1)
            },
            {
                header: { upperPart: 'March', lowerPart: '2022' },
                executionStatusToOrganisationCount: new Map<AssuranceMethodExecutionStatusDto, number>()
                    .set(AssuranceMethodExecutionStatusDto.PLANNED, 16)
                    .set(AssuranceMethodExecutionStatusDto.LATE, 1)
            },
            {
                header: { upperPart: 'April', lowerPart: '2022' },
                executionStatusToOrganisationCount: new Map<AssuranceMethodExecutionStatusDto, number>()
                    .set(AssuranceMethodExecutionStatusDto.PLANNED, 16)
                    .set(AssuranceMethodExecutionStatusDto.LATE, 1)
            }
        ]
    },
    {
        methodId: 2,
        fullMethodName: 'Service Audit-Critical',
        shortMethodName: 'Se. A. Crit.',
    },
    {
        methodId: 3,
        fullMethodName: 'Authorized Supplier Asmt.',
        shortMethodName: 'Au. Sup. Asmt.',
    },
    {
        methodId: 4,
        fullMethodName: 'Low Impact Supplier Asmt.',
        shortMethodName: 'L. Im. Sup. As.',
        cells: [
            {
                header: { upperPart: 'January', lowerPart: '2022' },
                executionStatusToOrganisationCount: new Map<AssuranceMethodExecutionStatusDto, number>()
                    .set(AssuranceMethodExecutionStatusDto.COMPLETED, 20)
                    .set(AssuranceMethodExecutionStatusDto.STARTED, 120)
                    .set(AssuranceMethodExecutionStatusDto.PLANNED, 10)
                    .set(AssuranceMethodExecutionStatusDto.LATE, 1)
            },
            {
                header: { upperPart: 'April', lowerPart: '2022' },
                executionStatusToOrganisationCount: new Map<AssuranceMethodExecutionStatusDto, number>()
                    .set(AssuranceMethodExecutionStatusDto.COMPLETED, 3)
                    .set(AssuranceMethodExecutionStatusDto.LATE, 9)
            }
        ]
    },
    {
        methodId: 5,
        fullMethodName: 'Privacy Assessment',
        shortMethodName: 'Priv. Asmt.',
    },
    {
        methodId: 6,
        fullMethodName: 'Darkbeam monitoring',
        shortMethodName: 'D. Mon.',
    },
    {
        methodId: 7,
        fullMethodName: 'Vendore Score monitoring',
        shortMethodName: 'V. S. Mon.',
    },
    {
        methodId: 8,
        fullMethodName: 'Supplier Impact Assessment',
        shortMethodName: 'Sup. Im. Asmt.',
    },
    {
        methodId: 9,
        fullMethodName: 'Contract Terms',
        shortMethodName: 'Cont. terms',
    }
];

const columnDefinitionsMonthly: ExecutionPlanColumnDefinitionDto[] = [
    {
        header: { upperPart: '5', lowerPart: 'Wednesday' },
        workloadPercentage: 10
    },
    {
        header: { upperPart: '6', lowerPart: 'Thursday' },
        workloadPercentage: 55,
    },
    {
        header: { upperPart: '7', lowerPart: 'Friday' },
        workloadPercentage: 30,
    },
    {
        header: { upperPart: '8', lowerPart: 'Saturday' },
        workloadPercentage: 99,
    },
    { header: { upperPart: '9', lowerPart: 'Sunday' } },
    { header: { upperPart: '10', lowerPart: 'Monday' } },
    {
        header: { upperPart: '11', lowerPart: 'Tuesday' },
        workloadPercentage: 50,
    },
    { header: { upperPart: '12', lowerPart: 'Wednesday' } },
    { header: { upperPart: '13', lowerPart: 'Thursday' } },
    {
        header: { upperPart: '14', lowerPart: 'Friday' },
        workloadPercentage: 67.8,
    },
    {
        header: { upperPart: '15', lowerPart: 'Saturday' },
        workloadPercentage: 17.5,
    },
    { header: { upperPart: '16', lowerPart: 'Sunday' } },
];

export const EXECUTION_PLAN_YEARLY: ExecutionPlanDto = { columnDefinitions: columnDefinitionsYearly, statusRows };
export const EXECUTION_PLAN_MONTHLY: ExecutionPlanDto = { columnDefinitions: columnDefinitionsMonthly, statusRows };