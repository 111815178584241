import { Close } from "@mui/icons-material";
import { Alert, Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Dropzone, { Accept, DropEvent, FileRejection } from "react-dropzone";

interface FileDropZoneProps {
    fileSizeLimit: number; // in bytes
    dragAndDropMessage?: string;
    acceptFileTypes: Accept;
    fileUploaded: (file: UrlFile) => void;
    resetFile: () => void;
}

export interface UrlFile {
    name: string;
    dataUrl: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
}

export function FileDropZone(props: FileDropZoneProps) {

    const [file, setFile] = useState<File>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    function fileUploadError(err: Error) {
        setErrorMessage(err.message);
    }

    function fileUploadRejected(fileRejections: FileRejection[], event: DropEvent) {
        let errors: string[] = [fileRejections[0].file.name];
        fileRejections[0].errors.forEach(error => errors.push(error.message));
        setErrorMessage(errors.join(', '));
    }

    function reset() {
        setFile(undefined);
        setErrorMessage(undefined);
        props.resetFile();
    }

    const fileLabel = () => {
        return file!.name + " (" + Math.ceil(file!.size / 1024) + " KiB)";
    };

    const fileSelected = (files: File[]) => {

        if (files.length > 0) {
            const reader = new FileReader();
            setFile(files[0]);
            reader.readAsDataURL(files[0]);
            reader.addEventListener("load", () => {
                setErrorMessage(undefined);
                props.fileUploaded({ name: files[0].name, dataUrl: reader.result as string });
            });
        } else {
            reset();
        }
    };

    return (
        <>
            <Dropzone
                onDropAccepted={fileSelected}
                maxFiles={1}
                maxSize={props.fileSizeLimit}
                accept={props.acceptFileTypes}
                onError={fileUploadError}
                onDropRejected={fileUploadRejected}
                useFsAccessApi={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <Box sx={{ p: 1 }} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Typography sx={{ p: 3, color: "text.secondary"}} align="center">
                            {props.dragAndDropMessage ? props.dragAndDropMessage : "Drag and drop a file here or click to select"}
                        </Typography>
                    </Box>
                )}
            </Dropzone>
            {file && <Stack margin={1} alignItems='baseline'><Chip label={fileLabel()} variant="outlined" onDelete={reset} /></Stack>}
            {
                errorMessage &&
                <Alert
                    severity='error'
                    action={<IconButton color="inherit" size="small" onClick={() => {
                        setErrorMessage(undefined);
                    }}><Close fontSize="inherit" /></IconButton>}
                    sx={{ my: 1 }}
                >
                    {errorMessage}
                </Alert>
            }
        </>
    );
}