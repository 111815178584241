import { Box, menuItemClasses, svgIconClasses } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
    DataGrid,
    GridCellParams,
    gridClasses,
    GridClassKey,
    GridColDef,
    GridFilterItem,
    GridFilterOperator,
    GridRenderCellParams,
    GridSeparatorIcon
} from "@mui/x-data-grid";
import { theme } from "../../../root/theme";
import { unhandledCase } from "../../../util/Common";
import { AssuranceGroupNameLink } from "../components/AssuranceGroupNameLink";
import { AssuranceMethodNameLink } from "../components/AssuranceMethodNameLink";
import { ContractTermsCell } from "../components/ContractTermsCell";
import { ExecutionFrequencyCell } from "../components/ExecutionFrequencyCell";
import { CheckboxFilterOption } from "../components/toolbar/CheckboxFilter";
import { PolicyMatrixToolbar } from "../components/toolbar/PolicyMatrixToolbar";
import {
    AssuranceMethodTypeDto,
    ContractTermDto,
    ExecutionFrequencyRequirementDto,
    PolicyMatrixColumnDefinitionDto,
    PolicyMatrixDto,
    PolicyMatrixRowDto,
} from "../PolicyMatrixModel";

function toGridColDef(columnDefinition: PolicyMatrixColumnDefinitionDto): GridColDef {
    const EMPTY_CELL_CONTENT = '-';

    const commonProps: GridColDef = {
        field: columnDefinition.fullName,
        headerName: columnDefinition.shortName,
        renderHeader: () => <Box sx={{ maxHeight: '2.6rem', lineHeight: theme.typography.body2.lineHeight }}>
            <AssuranceMethodNameLink
                methodId={columnDefinition.methodId}
                name={columnDefinition.shortName}
                tooltipTitle={columnDefinition.fullName}
            />
        </Box>,
        sortable: false
    };

    switch (columnDefinition.methodType) {
        case AssuranceMethodTypeDto.ASSESSMENT:
        case AssuranceMethodTypeDto.DARKBEAM:
        case AssuranceMethodTypeDto.AUDIT:
        case AssuranceMethodTypeDto.DATA_LOOKUP:
            return {
                ...commonProps,
                flex: 1,
                renderCell: (params: GridRenderCellParams<ExecutionFrequencyRequirementDto>) => (
                    params.value ? <ExecutionFrequencyCell
                            assuranceMethodId={columnDefinition.methodId}
                            assuranceGroupId={params.row.id}
                            executionFrequency={params.value.executionFrequency}
                            executionStatus={params.value.executionStatus}
                        />
                        : EMPTY_CELL_CONTENT
                )
            };
        case AssuranceMethodTypeDto.CONTRACT_TERMS:
            return {
                ...commonProps,
                flex: 1.2,
                valueGetter: (it: { value: { tags: ContractTermDto[] } }) => it.value?.tags,
                renderCell: (params: GridRenderCellParams<ContractTermDto[]>) => (
                    params.value ? <ContractTermsCell contractTerms={params.value} /> : EMPTY_CELL_CONTENT
                )
            };
        default:
            unhandledCase(columnDefinition.methodType);
    }
}

interface PolicyMatrixPageProps {
    policyMatrix: PolicyMatrixDto;
}

export function PolicyMatrixPage({ policyMatrix }: PolicyMatrixPageProps) {
    const GROUP_HEADER_NAME = 'Group';

    const isGroupShortNameInArray: GridFilterOperator = {
        value: 'isGroupShortNameInArray',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }

            return (params: GridCellParams<PolicyMatrixRowDto>): boolean => {
                return filterItem.value.indexOf(params.value!.shortName) > -1;
            };
        }
    };

    const groupColumnDefinition: GridColDef = {
        field: GROUP_HEADER_NAME,
        headerName: GROUP_HEADER_NAME,
        renderCell: (params: GridRenderCellParams<PolicyMatrixRowDto>) =>
            <Box sx={{ maxHeight: '2.6rem', overflow: 'hidden' }}>
                <AssuranceGroupNameLink groupId={params.row.id} name={params.value!.shortName} tooltipTitle={params.value!.fullName} />
            </Box>,
        flex: 1.1,
        headerClassName: 'group-header',
        filterOperators: [isGroupShortNameInArray]
    };

    const columnDefinitions: GridColDef[] = [groupColumnDefinition].concat(
        policyMatrix.columnDefinitions.map(method => toGridColDef(method))
    );

    const rows = policyMatrix.rows.map(group => {
        const row = {
            id: group.groupId,
            [GROUP_HEADER_NAME]: group
        };

        group.cells?.forEach(cell =>
            Object.assign(row, { [cell.methodName]: cell.value })
        );

        return row;
    });

    const groupFilterOptions: CheckboxFilterOption[] = policyMatrix.rows.map(row => {
        return { value: row.shortName, label: row.fullName };
    });
    const methodFilterOptions: CheckboxFilterOption[] = policyMatrix.columnDefinitions.map(columnDefinition => {
        return { value: columnDefinition.fullName };
    });

    return (
        <Box sx={{
            display: 'flex',
            height: '100%',
            '& .even-row': {
                bgcolor: grey[50]
            },
            '& .group-header': {
                [`& .${gridClasses.columnHeaderTitle}`]: {
                    fontWeight: 'bold'
                }
            }
        }}>
            <DataGrid
                rows={rows}
                columns={columnDefinitions}
                localeText={{ toolbarExport: 'Download' }}
                autoHeight
                disableColumnMenu={true}
                disableSelectionOnClick={true}
                hideFooter={true}
                density={'standard'}
                components={{
                    ColumnResizeIcon: () => <GridSeparatorIcon viewBox='-11 7 48 10' preserveAspectRatio='none' />,
                    Toolbar: PolicyMatrixToolbar
                }}
                componentsProps={{
                    toolbar: {
                        groupFilterOptions,
                        methodFilterOptions,
                        policiesAlertCount: policyMatrix.policiesAlertCount,
                    },
                    basePopper: {
                        sx: {
                            [`& .${gridClasses.menuList}`]: {
                                p: 0,
                                [`& .${menuItemClasses.root}`]: {
                                    px: 1.5,
                                    py: 0.75,
                                    fontSize: theme.typography.caption.fontSize,
                                    lineHeight: theme.typography.caption.lineHeight,
                                    minHeight: 'fit-content'
                                }
                            },
                        }
                    }
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'even-row'
                }
                sx={{
                    border: 0,
                    [`& .${gridClasses.cell}`]: {
                        borderRight: 1, borderColor: grey[200], position: 'relative'
                    },
                    [`& .${gridClasses.row}:not(.${gridClasses['row--dynamicHeight' as GridClassKey]})`]: {
                        [`&>.${gridClasses.cell}`]: {
                            whiteSpace: 'normal'
                        }
                    },
                    [`& .${gridClasses.columnHeaders}`]: { borderBottom: 0 },
                    [`& .${gridClasses.columnSeparator}`]: {
                        [`& .${svgIconClasses.root}`]: {
                            fill: '#000000',
                            overflow: 'visible'
                        }
                    },
                    [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                        whiteSpace: 'normal',
                    },
                    [`& .${gridClasses.filterIcon}`]: {
                        visibility: 'hidden'
                    },
                    [`&.${gridClasses.autoHeight}`]: {
                        [`& .${gridClasses['row--lastVisible'] as GridClassKey}`]: {
                            [`& .${gridClasses.cell}`]: {
                                borderColor: grey[200]
                            },
                        }
                    }
                }}
            />
        </Box>
    );
}