import { Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent } from "react";
import { useController, useFormContext } from "react-hook-form";
import { AssuranceMethodInputValues, Duration } from "../AssuranceMethodInputFormModel";
import { DurationInput } from "./DurationInput";

function InfoConfidenceLevelInput() {

    const name = "infoConfidenceLevel";

    const { control } = useFormContext<AssuranceMethodInputValues>();

    const { field: { onChange, ...rest } } = useController({
        name: name,
        control: control,
    });

    return (
        <Box>
            <Typography sx={{ mb: 2 }}>
                Maximum level of information confidence at the time of execution for this assurance method
            </Typography>

            <TextField
                {...rest}
                id={name}
                variant="standard"
                label="Level"
                type="number"
                inputProps={{ min: 1, max: 100 }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
                helperText={"From 1 to 100"}
                onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(+event.target.value)}
                sx={{
                    width: {
                        xs: "60%",
                        sm: "20%",
                        md: "10%"
                    }
                }}
            />
        </Box>
    );
}

function InfoConfidenceHalfLifeTimeInput() {

    const { control, getValues } = useFormContext<AssuranceMethodInputValues>();

    const name = "infoConfidenceHalfLifeTime";

    const { field: { onChange } } = useController({
        name: name,
        control: control,
    });

    return (
        <Box>
            <Typography sx={{ mb: 2 }}>
                Rate at which the information confidence level deteriorates over time
            </Typography>

            <DurationInput
                id={name}
                initDuration={getValues(name)}
                onChange={(newState: Duration | null) => onChange(newState)}
                label="Half-life time"
                helperText="Half-life time"
            />
        </Box>
    );
}

export function InformationConfidenceInput() {
    return (
        <Stack sx={{ rowGap: 4 }}>
            <InfoConfidenceLevelInput />
            <InfoConfidenceHalfLifeTimeInput />
        </Stack>
    );
}