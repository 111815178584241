import { useParams } from "react-router-dom";
import { AssuranceGroupViewDto } from "../../GroupsModel";
import { AssuranceGroupInputForm } from "./AssuranceGroupInputForm";
import { AssuranceGroupInputFormInputValues } from "./AssuranceGroupInputFormModel";

export function EditAssuranceGroupPage() {
    const { assuranceGroupId } = useParams();

    // TODO temporary data, use api
    //const { data, error: getUserError, isLoading, isSuccess, isError } = useGetAssuranceGroupDetailsByIdQuery(Number(assuranceGroupId));
    const data: AssuranceGroupViewDto = {
        assuranceGroupData: {
            name: "High Impact Supplier",
            shortName: "H.Imp.Sup.",
            shortDescription: "High Impact Supplier for services included in new project",
            description: "Managing quality is a crucial process for any business. Ensuring that you have a consistently high-quality product or service translates into satisfied customers, increased sales and ultimately more dollars in the bank. In today’s very competitive marketplace, having a reputation as a high-performing, quality business could be a critical differentiator for your company."
        },
        assuranceGroupElements: [
            {
                assuranceGroupElementId: 1,
                name: "8-bit",
                primaryContact: "John",
                criticality: "high",
                impact: "low",
                tags: [
                    {
                        name: "Commodore 64",
                        color: "#42a5f5"
                    },
                    {
                        name: "Atari 800XL",
                        color: "#ef6c00"
                    },
                    {
                        name: "MSX",
                        color: "#ab47bc"
                    },
                ]
            },
            {
                assuranceGroupElementId: 2,
                name: "16-bit",
                primaryContact: "Kolia",
                criticality: "low",
                impact: "low",
                tags: [
                    {
                        name: "Amiga 500",
                        color: "#ef5350"
                    },
                ]
            },
        ]
    };

    const formInitialData = (assuranceGroupView: AssuranceGroupViewDto) => {
        const initialData: AssuranceGroupInputFormInputValues = {
            name: assuranceGroupView.assuranceGroupData.name,
            shortName: assuranceGroupView.assuranceGroupData.shortName,
            shortDescription: assuranceGroupView.assuranceGroupData.shortDescription,
            description: assuranceGroupView.assuranceGroupData.description!,
            elements: assuranceGroupView.assuranceGroupElements
        };

        return initialData;
    };

    function editAssuranceGroupAction(formData: AssuranceGroupInputFormInputValues) {
        console.log(formData);
    }

    const onCancelOperation = () => {
    };

    return (
        <AssuranceGroupInputForm
            onSubmitOperation={editAssuranceGroupAction}
            onCancelOperation={onCancelOperation}
            initialData={formInitialData(data!)}
        />
    );
}
