import { createTheme } from "@mui/material";
import { amber, blue, green, grey, orange, red } from "@mui/material/colors";

declare module '@mui/material/styles' {
    interface Theme {
        assuranceMethodExecutionStatusColor: {
            completed: string;
            started: string;
            late: string;
            planned: string;
        };
        assuranceTaskStatusColor: {
            planned: string;
            started: string;
            completed: string;
        };
    }

    interface ThemeOptions {
        assuranceMethodExecutionStatusColor?: {
            completed?: string;
            started?: string;
            late?: string;
            planned?: string;
        };
        assuranceTaskStatusColor?: {
            planned?: string;
            started?: string;
            completed?: string;
        };
    }
}

const commonSettings = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiLink: {
            defaultProps: {
                variant: 'body2',
                underline: 'none',
                color: blue[500]
            }
        },
    },
    assuranceMethodExecutionStatusColor: {
        completed: green['A700'],
        started: blue[500],
        late: red['A200'],
        planned: orange[300],
    },
    assuranceTaskStatusColor: {
        planned: amber[900],
        started: blue[500],
        completed: green['A700'],
    },
};

const theme = createTheme({
    palette: {
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF'
        },
        primary: amber,
        secondary: blue,
        text: {
            primary: grey[900],
            secondary: grey[500]
        }
    },
    typography: {
        caption: {
            color: grey[500]
        },
        h6: {
            fontWeight: 550
        }
    }
}, commonSettings);

export { theme };