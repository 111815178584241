import ClearIcon from "@mui/icons-material/Clear";
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import * as React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Frequency, Period } from "../AssuranceMethodInputFormModel";

interface FrequencyInputProps {
    id: string;
    initFrequency: Frequency | null;
    onChange: (state: Frequency | null) => void;
    label: string;
    helperText?: string;
}

export function FrequencyInput(props: FrequencyInputProps) {

    const {
        id,
        initFrequency,
        onChange,
        label,
        helperText,
    }: FrequencyInputProps = props;

    const [frequency, setFrequency] = useState<Frequency | null>(initFrequency);

    const onChangeCallback = useRef<(state: Frequency | null) => void>(onChange);

    useEffect(() => {
        onChangeCallback.current(
            frequency?.rate && frequency?.period
                ? { rate: frequency.rate, period: frequency.period } as Frequency
                : null
        );
    }, [frequency?.rate, frequency?.period, onChangeCallback]);

    function clearRate() {
        setFrequency((prevFrequency: Frequency | null) => {
            return { ...prevFrequency, rate: "" } as Frequency;
        });
    }

    function updateRate(newRate: string) {
        setFrequency((prevFrequency: Frequency | null) => {
            return { ...prevFrequency, rate: newRate } as Frequency;
        });
    }

    function updatePeriod(newPeriod: Period) {
        setFrequency((prevFrequency: Frequency | null) => {
            return { ...prevFrequency, period: newPeriod } as Frequency;
        });
    }

    return (
        <Grid container columnSpacing={2} sx={{ alignItems: { sm: "center" } }}>
            <Grid item xs={12} sm={3} md={2}>
                <TextField
                    id={`${id}-rate`}
                    value={frequency?.rate ?? ""}
                    variant="standard"
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    helperText={helperText}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => updateRate(event.target.value)}
                    InputProps={{
                        endAdornment:
                            <IconButton
                                onClick={clearRate}
                                sx={{ visibility: frequency?.rate ? "visible" : "hidden" }}
                            >
                                <ClearIcon sx={{ fontSize: "medium" }} />
                            </IconButton>
                    }}
                    sx={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={12} sm={1} md={0.5} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>per</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <FormControl
                    variant="standard"
                    sx={{ width: "100%" }}
                >
                    <InputLabel id={`${id}-periodLabel`}>Period</InputLabel>
                    <Select
                        id={`${id}-period`}
                        value={frequency?.period ?? "" as Period}
                        labelId={`${id}-periodLabel`}
                        label="Period"
                        disabled={!frequency?.rate}
                        onChange={(event: SelectChangeEvent<Period>) => updatePeriod(event.target.value as Period)}
                    >
                        {Object.values(Period).map((period: Period) =>
                            <MenuItem key={period} value={period}>{period}</MenuItem>
                        )}
                    </Select>
                    <FormHelperText>Time</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
}