import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField, } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Controller, useFormContext } from "react-hook-form";
import { FileUpload } from "../../../../../../components/fileUploading/FileUpload";
import {
    Assessment,
    AssuranceMethodInputValues,
    Contact,
    EXAMPLE_ASSESSMENTS,
    EXAMPLE_CONTACTS
} from "../../AssuranceMethodInputFormModel";
import { RecipientPermissionsInput } from "./RecipientPermissionsInput";

const FILE_SIZE_LIMIT: number = 1048576;

export function AuditInstructionInput() {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    return (
        <Grid container spacing={4}>
            <Grid container item spacing={4}>
                <Grid item xs={12} sm={4} md={3}>
                    <Controller
                        name="auditInstruction.recipientName"
                        rules={{ required: "Recipient is required" }}
                        render={({ field: { onChange, ...rest }, fieldState: { error } }) =>
                            <Autocomplete
                                {...rest}
                                id="auditInstruction.recipientName"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        label="Recipient name"
                                        error={!!error}
                                        helperText={error ? error.message : "Audit instruction recipient name"}
                                    />
                                }
                                popupIcon={<SearchIcon />}
                                options={EXAMPLE_CONTACTS}
                                getOptionLabel={(contact: Contact) => contact.firstName + " " + contact.lastName}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(_, contact: Contact | null) => onChange(contact)}
                                sx={{
                                    "& .MuiAutocomplete-popupIndicator": {
                                        transform: "none"
                                    }
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Controller
                        name="auditInstruction.defaultAssessor"
                        render={({ field: { onChange, ...rest } }) =>
                            <Autocomplete
                                {...rest}
                                id="auditInstruction.defaultAssessor"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Default assessor"
                                        helperText="Audit instruction Rizikon assessment default assessor"
                                    />
                                }
                                popupIcon={<SearchIcon />}
                                options={EXAMPLE_CONTACTS}
                                getOptionLabel={(contact: Contact) => contact.firstName + " " + contact.lastName}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(_, contact: Contact | null) => onChange(contact)}
                                sx={{
                                    "& .MuiAutocomplete-popupIndicator": {
                                        transform: "none"
                                    }
                                }}
                            />
                        }
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Controller
                    name="auditInstruction.assessment"
                    render={({ field: { onChange, ...rest } }) =>
                        <Autocomplete
                            {...rest}
                            id="auditInstruction.assessment"
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Rizikon assessment"
                                    helperText="Audit instruction Rizikon assessment"
                                />
                            }
                            popupIcon={<SearchIcon />}
                            options={EXAMPLE_ASSESSMENTS}
                            getOptionLabel={(assessment: Assessment) => assessment.description}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(_, assessment: Assessment | null) => onChange(assessment)}
                            sx={{
                                "& .MuiAutocomplete-popupIndicator": {
                                    transform: "none"
                                }
                            }}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <RecipientPermissionsInput
                    name="allowRecipientToEditAssessor"
                    label="Allow Recipient to edit Assessor?" />
            </Grid>
            <Grid item xs={12}>
                <RecipientPermissionsInput
                    name="allowRecipientToAddCompleters"
                    label="Allow Recipient to add Completers?" />
            </Grid>
            <Grid item xs={12} sm={9}>
                <Controller
                    name="auditInstruction.emailContent"
                    control={control}
                    rules={{ required: "Email content is required" }}
                    render={({ field, fieldState: { error } }) =>
                        <TextField
                            {...field}
                            required
                            multiline
                            rows={4}
                            id="emailContent"
                            label="Email content"
                            error={!!error}
                            helperText={error ? error.message : "Audit instruction email content"}
                            sx={{ width: "100%" }}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                <Controller
                    name="auditInstruction.instructionFile"
                    control={control}
                    render={({ field: { onChange } }) =>
                        //TODO: define accepted files
                        <FileUpload
                            acceptFileTypes={{ 'text/*': [], "application/pdf": [] }}
                            onChange={onChange}
                            fileSizeLimit={FILE_SIZE_LIMIT}
                            label="Instructions"
                            hintMessage="Audit instruction documentation"
                        />
                    }
                />
            </Grid>
        </Grid>
    );
}