import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { unhandledCase } from "../../../util/Common";
import { AssuranceTaskDto, AssuranceTaskStatusDto, AssuranceTaskTypeDto } from "../ExecutionPlanModel";
import { ReactComponent as ManualIcon } from "../../../icons/manual.svg";
import { ReactComponent as AutomaticIcon } from "../../../icons/automatic.svg";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface AssuranceTaskProps {
    assuranceTask: AssuranceTaskDto;
};

export function AssuranceTask(props: AssuranceTaskProps) {

    const theme = useTheme();
    const statusColor = theme.assuranceTaskStatusColor;

    const getStatusAppearance: () => { color: string; label: string; dueDateColor: string } = () => {
        switch (props.assuranceTask.status) {
            case AssuranceTaskStatusDto.PLANNED:
                return {
                    color: statusColor.planned,
                    label: "Planned",
                    dueDateColor: "inherit"
                };
            case AssuranceTaskStatusDto.STARTED:
                return {
                    color: statusColor.started,
                    label: "Started",
                    dueDateColor: "inherit"
                };
            case AssuranceTaskStatusDto.COMPLETED:
                return {
                    color: statusColor.completed,
                    label: "Completed",
                    dueDateColor: statusColor.completed
                };
            default:
                unhandledCase(props.assuranceTask.status);
        }
    };

    const getTypeAppearance = () => {
        switch (props.assuranceTask.type) {
            case AssuranceTaskTypeDto.MANUAL:
                return {
                    label: "Manual",
                    icon: <ManualIcon />
                };
            case AssuranceTaskTypeDto.AUTOMATIC:
                return {
                    label: "Automatic",
                    icon: <AutomaticIcon />
                };
            default:
                unhandledCase(props.assuranceTask.type);
        }
    };

    const statusAppearance = getStatusAppearance();
    const typeAppearance = getTypeAppearance();

    return (
        <Box border={1} borderRadius={"0.5rem"} borderColor={statusAppearance.color} m={1} sx={{ position: "relative", minWidth: "13rem", maxWidth: "13rem" }}>
            <Stack direction="row" alignItems={"flex-start"} justifyContent={"space-between"} marginTop={-0.1} marginLeft={-0.1}>
                <Typography variant="caption" color={"background.default"}>
                    <Box borderRadius={"0.5rem 0 0.5rem 0"} sx={{ backgroundColor: statusAppearance.color, px: 0.8 }}>
                        {statusAppearance.label}
                    </Box>
                </Typography>
                <MuiLink component={RouterLink} to={`/executionplan/taskview`}>
                    <IconButton><MoreVert sx={{ color: statusAppearance.color }} /></IconButton>
                </MuiLink>
            </Stack>
            <Box px={1.5} marginTop={-2}>
                <Typography variant="caption">Target</Typography>
                <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.assuranceTask.target}</Box>
                <Typography variant="caption">Due date</Typography>
                <Box sx={{ color: statusAppearance.dueDateColor, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{props.assuranceTask.dueDate}</Box>
            </Box>
            <Stack direction="row-reverse" alignItems={"flex-end"} justifyContent={"flex-start"} marginBottom={-0.1} marginRight={-0.1}>
                <Typography variant="caption" color={"background.default"} sx={{ textAlign: "end" }}>
                    <Box borderRadius={"0.5rem 0 0.5rem 0"} sx={{ backgroundColor: statusAppearance.color, px: 0.8 }}>
                        {typeAppearance.label}
                    </Box>
                </Typography>
            </Stack>
            <SvgIcon sx={{ color: statusAppearance.color, top: "0.8rem", position: "absolute", right: "0.5rem", zIndex: -100, opacity: 0.2, width: "4em", height: "4em" }}>
                {typeAppearance.icon}
            </SvgIcon>
        </Box >
    );
};
