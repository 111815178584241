import { Link as MuiLink, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface AssuranceGroupNameProps {
    groupId: number;
    name: string;
    tooltipTitle?: string;
}

export function AssuranceGroupNameLink({ groupId, name, tooltipTitle }: AssuranceGroupNameProps) {
    return (
        <Tooltip title={tooltipTitle}>
            <MuiLink component={RouterLink} to={"/assurancegroups/edit"}>
                {name}
            </MuiLink>
        </Tooltip>
    );
}