import { Box, svgIconClasses } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid, GridCellParams, gridClasses, GridClassKey, GridColDef, GridRenderCellParams, GridSeparatorIcon } from "@mui/x-data-grid";
import { ReactNode, useState } from "react";
import { ExecutionPlanDetailedViewPage } from "./ExecutionPlanDetailedView/ExecutionPlanDetailedViewPage";
import { theme } from "../../../root/theme";
import { AssuranceMethodNameLink } from "../../policyMatrix/components/AssuranceMethodNameLink";
import { AssuranceMethodExecutionStatusDto } from "../../policyMatrix/PolicyMatrixModel";
import { ExecutionPlanHeader } from "../components/ExecutionPlanHeader";
import { ExecutionPlanStatusCell } from "../components/ExecutionPlanStatusCell";
import { ExecutionPlanWorkloadCell } from "../components/ExecutionPlanWorkloadCell";
import { CheckboxFilterOption } from "../components/toolbar/CheckboxFilter";
import { ExecutionPlanToolbar } from "../components/toolbar/ExecutionPlanToolbar";
import {
    ExecutionPlanColumnDefinitionDto,
    ExecutionPlanDto,
    ExecutionPlanHeaderDto,
    ExecutionPlanStatusRowDto
} from "../ExecutionPlanModel";
import { EXECUTION_PLAN_MONTHLY, EXECUTION_PLAN_YEARLY } from "../mockData/ExecutionPlanMockData";

interface ExecutionPlanPageProps {
    executionPlan: ExecutionPlanDto;
    onOverallViewClick: () => void;
    onDetailedViewClick: () => void;
}

function toHeaderName(header: ExecutionPlanHeaderDto): string {
    return `${header.upperPart} ${header.lowerPart}`;
}

export function ExecutionPlanPage() {

    const [viewVariant, setViewVariant] = useState<string>("yearly");

    const onOverallViewClick: () => void = () => {
        setViewVariant("yearly");
    };

    const onDetailedViewClick: () => void = () => {
        if (viewVariant === "yearly") {
            setViewVariant("monthly");
        }

        if (viewVariant === "monthly") {
            setViewVariant("daily");
        }
    };

    if (viewVariant === "yearly") {
        return <ExecutionPlanView onOverallViewClick={onOverallViewClick} onDetailedViewClick={onDetailedViewClick}
                                  executionPlan={EXECUTION_PLAN_YEARLY} />;
    }

    if (viewVariant === "monthly") {
        return <ExecutionPlanView onOverallViewClick={onOverallViewClick} onDetailedViewClick={onDetailedViewClick}
                                  executionPlan={EXECUTION_PLAN_MONTHLY} />;
    }

    if (viewVariant === "daily") {
        return <ExecutionPlanDetailedViewPage onOverallViewClick={onOverallViewClick} onDetailedViewClick={onDetailedViewClick}
                                              executionPlan={EXECUTION_PLAN_YEARLY} />;
    }

    return null;
}

export function ExecutionPlanView({ executionPlan, onOverallViewClick, onDetailedViewClick }: ExecutionPlanPageProps) {
    const ASSURANCE_METHOD_HEADER_NAME = 'Assurance method';
    const ASSURANCE_WORKLOAD_ROW_NAME = 'Assurance Workload';

    const toGridColDef = (header: ExecutionPlanHeaderDto, isInPresentTimeColumn?: boolean): GridColDef => {
        return {
            field: toHeaderName(header),
            renderHeader: () => <ExecutionPlanHeader isInPresentTimeColumn={isInPresentTimeColumn} header={header} />,
            width: 141,
            sortable: false,
            renderCell: (params: GridRenderCellParams<Map<AssuranceMethodExecutionStatusDto, number> | number>) =>
                params.row.id !== ASSURANCE_WORKLOAD_ROW_NAME ?
                    <ExecutionPlanStatusCell
                        isInPresentTimeColumn={isInPresentTimeColumn}
                        executionStatusToOrganisationCount={params.value as Map<AssuranceMethodExecutionStatusDto, number>}
                    /> :
                    <ExecutionPlanWorkloadCell workloadPercentage={params.value as number} />,
            cellClassName: (params: GridCellParams<Map<AssuranceMethodExecutionStatusDto, number> | number>) =>
                params.row.id === ASSURANCE_WORKLOAD_ROW_NAME ? 'assurance-workload-cell' : ''
        };
    };

    const methodColumnDefinition: GridColDef = {
        field: ASSURANCE_METHOD_HEADER_NAME,
        headerName: ASSURANCE_METHOD_HEADER_NAME,
        renderCell: (params: GridRenderCellParams<string>) => {
            const cellContent: ReactNode = params.row.id !== ASSURANCE_WORKLOAD_ROW_NAME ?
                <AssuranceMethodNameLink methodId={params.row.id} name={params.value!} /> :
                <Box sx={{ whiteSpace: 'break-spaces', fontWeight: 'bold' }}>{params.row.id}</Box>;

            return (
                <Box sx={{ maxHeight: '2.6rem' }}>
                    {cellContent}
                </Box>
            );
        },
        width: 116,
        headerClassName: 'method-header',
    };

    const columnDefinitionDtos: ExecutionPlanColumnDefinitionDto[] = executionPlan.columnDefinitions;
    const presentTimeColumnDefinition: GridColDef = toGridColDef(columnDefinitionDtos[0].header, true);
    const gridColumnDefinitions: GridColDef[] = [methodColumnDefinition, presentTimeColumnDefinition].concat(
        columnDefinitionDtos.slice(1, columnDefinitionDtos.length).map(columnDefinitionDto => toGridColDef(columnDefinitionDto.header))
    );

    const statusRows = executionPlan.statusRows.map(executionPlanDtoRow => {
        const gridRow = {
            id: executionPlanDtoRow.methodId,
            [ASSURANCE_METHOD_HEADER_NAME]: executionPlanDtoRow.shortMethodName
        };

        executionPlanDtoRow.cells?.forEach(cell =>
            Object.assign(gridRow, { [toHeaderName(cell.header)]: cell.executionStatusToOrganisationCount })
        );

        return gridRow;
    });

    const workloadRow = {
        id: ASSURANCE_WORKLOAD_ROW_NAME,
        [ASSURANCE_METHOD_HEADER_NAME]: ASSURANCE_WORKLOAD_ROW_NAME

    };
    columnDefinitionDtos.forEach(columnDefinitionDto =>
        Object.assign(workloadRow, { [toHeaderName(columnDefinitionDto.header)]: columnDefinitionDto.workloadPercentage })
    );

    const rows = [...statusRows, workloadRow];

    // FIXME: Demo data
    const groupFilterOptions: CheckboxFilterOption[] = [
        { value: "Crit. IT Ser.", label: "Critical IT Service" },
        { value: "Sup. IT Ser.", label: "Supporting IT Service" },
        { value: "Auth. Acc. R. Hol.", label: "Authorised Access Rights Holder" },
        { value: "D. Proc.", label: "Data Processor" },
    ];

    const methodFilterOptions: CheckboxFilterOption[] = executionPlan.statusRows.map((statusRow: ExecutionPlanStatusRowDto) => {
        return { value: statusRow.shortMethodName, label: statusRow.fullMethodName };
    });

    return (
        <Box sx={{
            display: 'flex',
            height: '100%',
            '& .even-row': {
                bgcolor: grey[50]
            },
            '& .method-header': {
                [`& .${gridClasses.columnHeaderTitle}`]: {
                    fontWeight: 'bold',
                    whiteSpace: 'break-spaces'
                },
                fontSize: theme.typography.caption.fontSize,
                lineHeight: theme.typography.caption.lineHeight,
            },
            '& .assurance-workload-cell': {
                [`&.${gridClasses.cell}`]: {
                    p: 0,
                }
            }
        }}>
            <DataGrid
                rows={rows}
                columns={gridColumnDefinitions}
                autoHeight
                disableColumnMenu={true}
                disableSelectionOnClick={true}
                hideFooter={true}
                density={'standard'}
                components={{
                    ColumnResizeIcon: () => <GridSeparatorIcon viewBox='-11 7 48 10' preserveAspectRatio='none' />,
                    Toolbar: ExecutionPlanToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        groupFilterOptions,
                        methodFilterOptions,
                        onOverallViewClick,
                        onDetailedViewClick
                    },
                }}
                getRowClassName={(params) =>
                    params.row.id !== ASSURANCE_WORKLOAD_ROW_NAME && params.indexRelativeToCurrentPage % 2 === 1 ? 'even-row' : ''
                }
                sx={{
                    border: 0,
                    [`& .${gridClasses.cell}`]: {
                        borderRight: 1, borderColor: grey[200]
                    },
                    [`& .${gridClasses.columnHeaders}`]: { borderBottom: 0 },
                    [`& .${gridClasses.columnSeparator}`]: {
                        [`& .${svgIconClasses.root}`]: {
                            fill: '#000000',
                            overflow: 'visible'
                        }
                    },
                    [`& .${gridClasses.columnHeaderTitleContainerContent}`]: {
                        height: '100%',
                        width: '100%'
                    },
                    [`&.${gridClasses.autoHeight}`]: {
                        [`& .${gridClasses['row--lastVisible'] as GridClassKey}`]: {
                            [`& .${gridClasses.cell}`]: {
                                borderColor: grey[200]
                            },
                            borderTop: 1,
                            borderBottom: 1,
                            borderColor: grey[200],
                        }
                    }
                }}
            />
        </Box>
    );
}