/**
 * Function to be used for `default` case of `switch` expressions on Enums types in order to make them exhaustive.
 * It gives <strong>compilation level</strong> protection that all Enum values are handled.
 * <br>
 * The error is never thrown as the body is never executed, it's there for compilation purposes.
 * <br>
 * More about TypeScript `never` type:
 * https://www.typescriptlang.org/docs/handbook/2/functions.html#never
 *
 * @param _ ignored parameter, necessary for compilation purposes
 */
export function unhandledCase(_: never): never {
    throw new Error("Unhandled case");
}
