import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { Assessment, AssuranceMethodInputValues, EXAMPLE_ASSESSMENTS } from "../AssuranceMethodInputFormModel";

export function RizikonAssessmentInput() {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    return (
        <Grid container>
            <Grid item xs={12} sm={5}>
                <Controller
                    name="rizikonAssessment.assessment"
                    control={control}
                    rules={{ required: "Recipient is required" }}
                    render={({ field: { onChange, ...rest }, fieldState: { error }  }) =>
                        <Autocomplete
                            {...rest}
                            id="rizikonAssessment.assessment"
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required
                                    label="Rizikon assessment"
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            }
                            popupIcon={<SearchIcon />}
                            options={EXAMPLE_ASSESSMENTS}
                            getOptionLabel={(assessment: Assessment) => assessment.description}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(_, assessment: Assessment | null) => onChange(assessment)}
                            sx={{
                                "& .MuiAutocomplete-popupIndicator": {
                                    transform: "none"
                                }
                            }}
                        />
                    }
                />
            </Grid>
        </Grid>
    );
}