import { Box, Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { AssuranceMethodInputValues } from "../AssuranceMethodInputFormModel";

export function MethodOwnerInput() {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    return (
        <Box>
            <Typography sx={{ mb: 3 }}>Person or team that defines and updates the Assurance Method.</Typography>

            <Grid container spacing={{ xs: 4 }}>
                <Grid item xs={4}>
                    <Controller
                        name="ownerName"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="ownerName"
                                label="Name"
                                variant="standard"
                                error={!!error}
                                helperText={error ? error.message : "Person or team"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={5}>
                    <Controller
                        name="ownerEmail"
                        control={control}
                        rules={{ required: "Email name is required" }}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                required
                                id="ownerEmail"
                                label="Contact email"
                                variant="standard"
                                error={!!error}
                                helperText={error ? error.message : "Contact email for Assurance Method Owner"}
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ownerInfo"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                id="ownerInfo"
                                label="Owner information"
                                multiline
                                rows={5}
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                helperText="Other useful information about Assurance Method Owner"
                                sx={{ width: "100%" }}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    );
}