import { FormControlLabel, Radio, useTheme } from "@mui/material";

interface RadioInputProps<T> {
    value: T;
    label: string;
}

export function RadioInput<T>(props: RadioInputProps<T>) {

    const theme = useTheme();

    return (
        <>
            <FormControlLabel
                value={props.value}
                label={props.label}
                control={
                    <Radio
                        sx={{
                            pr: 2,
                            "&.Mui-checked + .MuiFormControlLabel-label": {
                                color: theme.palette.text.primary,
                            }
                        }} />}
                sx={{
                    ".MuiFormControlLabel-label": {
                        color: theme.palette.text.secondary
                    },
                    width: "100%"
                }}
            />
        </>
    );
}