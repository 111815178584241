import { ReactNode } from "react";
import { Box, Card, CardContent, Divider, Typography } from "@mui/material";

interface InfoCardProps {
    title: string;
    description: string;
    children: ReactNode;
}

export function InfoCard({ title, description, children }: InfoCardProps) {
    return (
        <Card variant="elevation">
            <CardContent sx={{ px: 3 }}>
                <Box>
                    <Typography variant="h6" color={"text.secondary"}>{title}</Typography>
                    <Typography >{description}</Typography>
                    <Divider sx={{ py: 1 }} />
                    {children}
                </Box>
            </CardContent>
        </Card>
    );
}