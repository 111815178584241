export interface PolicyMatrixDto {
    columnDefinitions: PolicyMatrixColumnDefinitionDto[];
    rows: PolicyMatrixRowDto[];
    policiesAlertCount: number;
}

export interface PolicyMatrixColumnDefinitionDto {
    methodId: number;
    fullName: string;
    shortName: string;
    methodType: AssuranceMethodTypeDto;
}

export interface PolicyMatrixRowDto {
    groupId: number;
    fullName: string;
    shortName: string;
    cells?: PolicyMatrixCellDto[];
}

export interface PolicyMatrixCellDto {
    methodName: string;
    value: ExecutionFrequencyRequirementDto | ContractTermsRequirementDto;
}

export interface ExecutionFrequencyRequirementDto {
    executionFrequency: MethodExecutionFrequencyDto;
    executionStatus: AssuranceMethodExecutionStatusDto;
}

export interface MethodExecutionFrequencyDto {
    value?: string;
    type: MethodExecutionFrequencyTypeDto;
}

export interface ContractTermsRequirementDto {
    tags: ContractTermDto[];
}

export interface ContractTermDto {
    name: string;
    color: string;
}

export interface PolicyAlertsDialogDto {
    assuranceGroupName: string;
    assuranceMethodName: string;
    eventDetails?: string;
    statusRows?: PolicyAlertsDialogStatusRowDto[];
}

export interface PolicyAlertsDialogStatusRowDto {
    organisationIds: number[];
    executionStatus: AssuranceMethodExecutionStatusDto;
}

export enum AssuranceMethodTypeDto {
    ASSESSMENT = 'ASSESSMENT',
    AUDIT = 'AUDIT',
    CONTRACT_TERMS = 'CONTRACT_TERMS',
    DARKBEAM = 'DARKBEAM',
    DATA_LOOKUP = 'DATA_LOOKUP',
}

export enum AssuranceMethodExecutionStatusDto {
    LATE = 'LATE',
    COMPLETED = 'COMPLETED',
    STARTED = 'STARTED',
    PLANNED = 'PLANNED',
}

export enum MethodExecutionFrequencyTypeDto {
    REGULAR = 'REGULAR',
    CONTINUOUS = 'CONTINUOUS',
    ONCE = 'ONCE',
}