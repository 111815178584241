import {
    Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle, Divider, Grid,
    Theme,
    Typography,
    useTheme
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { AssuranceMethodExecutionStatusDto, PolicyAlertsDialogDto, PolicyAlertsDialogStatusRowDto } from "../PolicyMatrixModel";

interface PolicyAlertsDialogProps {
    assuranceMethodId: number;
    assuranceGroupId: number;
    open: boolean;
    onClose: () => void;
}

// TODO: remove when rtk query is set up
function getPolicyAlertsDialog(assuranceMethodId: number, assuranceGroupId: number): PolicyAlertsDialogDto {
    if (assuranceGroupId % 2 == 0 && assuranceMethodId) {
        return {
            assuranceMethodName: 'Darkbeam monitoring',
            assuranceGroupName: 'Critical IT Service',
            eventDetails: 'Executed whenever there is a security breach',
            statusRows: [
                {
                    organisationIds: [1, 5, 8, 9, 10, 12],
                    executionStatus: AssuranceMethodExecutionStatusDto.LATE,
                },
                {
                    organisationIds: [13],
                    executionStatus: AssuranceMethodExecutionStatusDto.PLANNED,
                },
            ]
        };
    }

    return {
        assuranceMethodName: 'Darkbeam monitoring',
        assuranceGroupName: 'Critical IT Service'
    };
}

export function PolicyAlertsDialog({ assuranceMethodId, assuranceGroupId, open, onClose }: PolicyAlertsDialogProps) {
    const policyAlertsDialogDto: PolicyAlertsDialogDto = getPolicyAlertsDialog(assuranceMethodId, assuranceGroupId);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle variant='body2' fontWeight='bold' align='center'>Policy alerts</DialogTitle>
            <DialogContent>
                <Grid container columns={2} columnSpacing={8} rowSpacing={2}>
                    <Grid item xs={2} sm={1}>
                        <PolicyAlertsDialogHeader value='Group' />
                        <PolicyAlertsDialogText value={policyAlertsDialogDto.assuranceGroupName} />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <PolicyAlertsDialogHeader value='Method' />
                        <PolicyAlertsDialogText value={policyAlertsDialogDto.assuranceMethodName} />
                    </Grid>
                    {policyAlertsDialogDto.eventDetails &&
                        <Grid item xs={2}>
                            <PolicyAlertsDialogHeader value='Event details' />
                            <PolicyAlertsDialogText value={policyAlertsDialogDto.eventDetails} />
                        </Grid>
                    }
                    {policyAlertsDialogDto.statusRows &&
                        <PolicyAlertsDialogStatusRows statusRows={policyAlertsDialogDto.statusRows} />
                    }
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-around', flexDirection: { xs: 'column', sm: 'row' } }}>
                <Button variant='contained'>
                    Show alerts
                </Button>
                <Button variant='text' onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface PolicyAlertsDialogStatusRowsProps {
    statusRows: PolicyAlertsDialogStatusRowDto[];
}

function PolicyAlertsDialogStatusRows({ statusRows }: PolicyAlertsDialogStatusRowsProps) {
    const theme: Theme = useTheme();

    const getStatusColorVariant = (executionStatus: AssuranceMethodExecutionStatusDto): string => {
        const methodExecutionStatusColor = theme.assuranceMethodExecutionStatusColor;

        switch (executionStatus) {
            case AssuranceMethodExecutionStatusDto.PLANNED:
                return methodExecutionStatusColor.planned;
            case AssuranceMethodExecutionStatusDto.LATE:
                return methodExecutionStatusColor.late;
            default:
                throw new Error(`Unsupported assurance method execution status: ${executionStatus}`);
        }
    };

    const getTargetTextValue = (organisationIds: number[]): string => {
        if (organisationIds.length > 1) {
            return `${organisationIds.length} Suppliers`;
        }

        return `${organisationIds.length} Supplier`;
    };

    const getStatusTextValue = (executionStatus: AssuranceMethodExecutionStatusDto): string => {
        return executionStatus.charAt(0) + executionStatus.slice(1).toLowerCase();
    };

    return (
        <>
            <Grid item xs={1}>
                <PolicyAlertsDialogHeader value='Target' />
            </Grid>
            <Grid item xs={1}>
                <PolicyAlertsDialogHeader value='Status' />
            </Grid>
            <Grid item xs={2} sx={{ mt: -1.5 }}>
                <Divider />
            </Grid>
            {statusRows.map((row) => (
                <>
                    <Grid item xs={1}>
                        <PolicyAlertsDialogText value={getTargetTextValue(row.organisationIds)} />
                    </Grid>
                    <Grid item xs={1} sx={{ color: getStatusColorVariant(row.executionStatus) }}>
                        <PolicyAlertsDialogText value={getStatusTextValue(row.executionStatus)} />
                    </Grid>
                </>
            ))}
        </>
    );
}

interface PolicyAlertsDialogHeaderProps {
    value: string;
}

function PolicyAlertsDialogHeader({ value }: PolicyAlertsDialogHeaderProps) {
    return (
        <Typography variant='caption'>
            {value}
        </Typography>
    );
}

interface PolicyAlertsDialogTextProps {
    value: string;
}

function PolicyAlertsDialogText({ value }: PolicyAlertsDialogTextProps) {
    return (
        <Typography variant='body2'>
            {value}
        </Typography>
    );
}