import { alpha, Box, Stack, Theme, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { Accept } from "react-dropzone";
import { FileDropZone, UrlFile } from "./FileDropZone";

interface FileUploadProps {
    fileSizeLimit: number;
    acceptFileTypes: Accept;
    label: string;
    hintMessage: string;
    onChange: (file: UrlFile | null) => void;
}

export function FileUpload(props: FileUploadProps) {

    const fileUploaded = (file: UrlFile) => {
        props.onChange(file);
    };

    const resetFile = () => {
        props.onChange(null);
    };

    return (
        <OutlinedContainer label={props.label} hintMessage={props.hintMessage}>
            <FileDropZone
                fileSizeLimit={props.fileSizeLimit}
                acceptFileTypes={props.acceptFileTypes}
                fileUploaded={fileUploaded}
                resetFile={resetFile}
                dragAndDropMessage={'Drag and drop a file here or click to select'}
            />
        </OutlinedContainer>
    );
}

interface OutlinedContainerProps {
    label: string;
    hintMessage: string;
}

function OutlinedContainer(props: PropsWithChildren<OutlinedContainerProps>) {

    return (
        <Stack direction="column" spacing={1}>
            <Box
                sx={{
                    width: '100%',
                    padding: 1,
                    borderRadius: '4px', // theme
                    border: 'solid 1px',
                    borderColor: (theme) => theme.palette.action.disabled,
                    '&:hover': {
                        borderColor: (theme) => alpha(theme.palette.action.active, 1)
                    }
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        position: "absolute",
                        marginTop: "-1.8em",
                        background: (theme: Theme) => theme.palette.background.default,
                        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))",
                        color: "text.secondary",
                        marginLeft: "0.2em",
                        padding: "0.4em"
                    }}
                >
                    {props.label}
                </Typography>

                {props.children}

            </Box>
            <Typography
                variant="caption"
                sx={{ color: "text.secondary" }}>
                {props.hintMessage}
            </Typography>
        </Stack>
    );
}
