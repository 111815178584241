import { UrlFile } from "../../../../components/fileUploading/FileDropZone";
import { unhandledCase } from "../../../../util/Common";
import {
    ApprovalRequestMethodDto,
    AssuranceMethodBasicInfoDto,
    AssuranceMethodCategoryDto,
    AssuranceMethodExecutionFrequencyDto,
    AuditInstructionMethodDto, RizikonAssessmentMethodDto
} from "../../AssuranceMethodsModel";

//FIXME: temporary model
export interface Assessment {
    id: number;
    description: string;
}

//FIXME: temporary data
export const EXAMPLE_ASSESSMENTS: Assessment[] = [
    {
        id: 1,
        description: "ACME/Modern Slavery (short)/2021/1",
    },
    {
        id: 2,
        description: "GenCo/Actual Import Volume/2022/4"
    },
    {
        id: 3,
        description: "ACME/Anti-bribery and Corruption/2022/4"
    }
];

//FIXME: temporary model
export interface Contact {
    id: number;
    firstName: string;
    lastName: string;
}

//FIXME: temporary data
export const EXAMPLE_CONTACTS: Contact[] = [
    {
        id: 1,
        firstName: "Jane",
        lastName: "Doe",
    },
    {
        id: 2,
        firstName: "John",
        lastName: "Smith",
    },
    {
        id: 3,
        firstName: "Emily",
        lastName: "Smith",
    },
    {
        id: 4,
        firstName: "Adam",
        lastName: "Jensen",
    },
    {
        id: 5,
        firstName: "Alexandra",
        lastName: "Jensen-Smith",
    },
];

export interface AuditInstructionInputValues {
    recipientName: Contact;
    defaultAssessor: Contact | null;
    assessment: Assessment | null;
    allowRecipientToEditAssessor: boolean;
    allowRecipientToAddCompleters: boolean;
    emailContent: string;
    instructionFile: UrlFile | null;
}

export interface RizikonAssessmentInputValues {
    assessment: Assessment | null;
}

export enum ApprovalRequestOption {
    ACTIVE = "ACTIVE",
    PAUSED = "PAUSED",
    INACTIVE = "INACTIVE",
}

export interface ApprovalRequestInputValues {
    emailContent: string;
    recipientName: Contact;
    email: string;
    systemID: string;
    approvalRequestOption: ApprovalRequestOption;
    documentationFile: UrlFile | null;
}

export enum Currency {
    GBP = "GBP",
    PLN = "PLN",
    EUR = "EUR",
    USD = "USD",
    CHF = "CHF",
}

export interface Cost {
    rate: string;
    currency: Currency;
}

export enum Period {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
}

export interface Duration {
    rate: string;
    unit: Period;
}

export interface Frequency {
    rate: string;
    period: Period;
}

export enum AssuranceMethodType {
    AUDIT_INSTRUCTION = "AUDIT_INSTRUCTION",
    APPROVAL_REQUEST = "APPROVAL_REQUEST",
    DARKBEAM_LOOKUP = "DARKBEAM_LOOKUP",
    RIZIKON_ASSESSMENT = "RIZIKON_ASSESSMENT",
}

// TODO: Replace the built-in validation with a schema-based validation tool
export interface AssuranceMethodInputValues {
    assuranceMethodType: AssuranceMethodType;
    auditInstruction: AuditInstructionInputValues;
    approvalRequest: ApprovalRequestInputValues;
    rizikonAssessment: RizikonAssessmentInputValues;
    name: string;
    shortName: string;
    shortDescription: string;
    fullDescription: string;
    categories: AssuranceMethodCategoryDto[];
    ownerName: string;
    ownerEmail: string;
    ownerInfo: string;
    executionStartDate: Date | null;
    executionEndDate: Date | null;
    executionFrequency: AssuranceMethodExecutionFrequencyDto[];
    startTimeBeforeDueDate: Duration;
    maxExecutionsNumber: Frequency | null;
    infoConfidenceLevel: number;
    infoConfidenceHalfLifeTime: Duration | null;
    totalCost: Cost | null;
    annualCost: Cost | null;
}

export function toAssuranceMethodModel(formData: AssuranceMethodInputValues) {

    switch (formData.assuranceMethodType) {
        case AssuranceMethodType.AUDIT_INSTRUCTION:
            return toAuditInstructionMethod(formData);
        case AssuranceMethodType.DARKBEAM_LOOKUP:
            return toMethodBasicInfo(formData);
        case AssuranceMethodType.RIZIKON_ASSESSMENT:
            return toRizikonAssessmentMethod(formData);
        case AssuranceMethodType.APPROVAL_REQUEST:
            return toApprovalRequestMethod(formData);
        default:
            return unhandledCase(formData.assuranceMethodType);
    }
}

function toMethodBasicInfo(formData: AssuranceMethodInputValues): AssuranceMethodBasicInfoDto {
    return {
        methodType: formData.assuranceMethodType,
        data: {
            name: formData.name,
            shortName: formData.shortName,
            shortDescription: formData.shortDescription,
            fullDescription: formData.fullDescription,
            categories: formData.categories,
        },
        methodOwner: {
            name: formData.ownerName,
            email: formData.ownerEmail,
            info: formData.ownerInfo,

        },
        execution: {
            startDate: formData.executionStartDate,
            endDate: formData.executionEndDate,
            frequency: formData.executionFrequency,
            startTimeBeforeDueDate: formData.startTimeBeforeDueDate,
            maxExecutionsNumber: formData.maxExecutionsNumber,
        },
        informationConfidence: {
            confidenceLevel: formData.infoConfidenceLevel,
            confidenceHalfLifeTime: formData.infoConfidenceHalfLifeTime,
        },
        costs: {
            totalCost: formData.totalCost,
            annualCost: formData.annualCost,
        },
    } as AssuranceMethodBasicInfoDto;
}

function toAuditInstructionMethod(formData: AssuranceMethodInputValues): AuditInstructionMethodDto {
    const auditInstructionData: AuditInstructionInputValues = formData.auditInstruction;
    return {
        basicInfo: toMethodBasicInfo(formData),
        recipientName: auditInstructionData.recipientName,
        defaultAssessor: auditInstructionData.defaultAssessor,
        assessment: auditInstructionData.assessment,
        allowRecipientToEditAssessor: Boolean(auditInstructionData.allowRecipientToEditAssessor),
        allowRecipientToAddCompleters: Boolean(auditInstructionData.allowRecipientToAddCompleters),
        emailContent: auditInstructionData.emailContent,
        instructionFile: auditInstructionData.instructionFile,
    } as AuditInstructionMethodDto;
}

function toRizikonAssessmentMethod(formData: AssuranceMethodInputValues): RizikonAssessmentMethodDto {
    return {
        basicInfo: toMethodBasicInfo(formData),
        assessment: formData.rizikonAssessment.assessment,
    } as RizikonAssessmentMethodDto;
}

function toApprovalRequestMethod(formData: AssuranceMethodInputValues): ApprovalRequestMethodDto {
    const approvalRequestData: ApprovalRequestInputValues = formData.approvalRequest;
    return {
        basicInfo: toMethodBasicInfo(formData),
        emailContent: approvalRequestData.emailContent,
        recipientName: approvalRequestData.recipientName,
        email: approvalRequestData.email,
        systemID: approvalRequestData.systemID,
        approvalRequestOption: approvalRequestData.approvalRequestOption,
        documentationFile: approvalRequestData.documentationFile,
    } as ApprovalRequestMethodDto;
}