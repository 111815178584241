import { FormControl, RadioGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Controller, UseControllerProps, useFormContext } from "react-hook-form";
import { AssuranceMethodInputValues, AuditInstructionInputValues } from "../../AssuranceMethodInputFormModel";
import { RadioInput } from "../RadioInput";

type AllowedRecipientPermissionsInputNames = "allowRecipientToEditAssessor" | "allowRecipientToAddCompleters";

interface RecipientPermissionsInputProps
    extends Pick<UseControllerProps<AuditInstructionInputValues, AllowedRecipientPermissionsInputNames>, "name"> {
    label: string;
}

export function RecipientPermissionsInput(props: RecipientPermissionsInputProps) {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    return (
        <>
            <Typography sx={{ mb: 3 }}>{props.label}</Typography>
            <Controller
                name={`auditInstruction.${props.name}`}
                control={control}
                render={({ field }) =>
                    <FormControl fullWidth>
                        <RadioGroup row sx={{ px: 2 }} {...field} >
                            <Grid container>
                                <Grid item xs={3}>
                                    <RadioInput<boolean> value={true} label="Yes" />
                                </Grid>
                                <Grid item xs={3}>
                                    <RadioInput<boolean> value={false} label="No" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                }
            />
        </>
    );
}