import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { GridColumnVisibilityModel, GridToolbarExport, useGridApiContext } from "@mui/x-data-grid";
import { useState } from "react";
import { ReactComponent as DownloadIcon } from "../../../../icons/download.svg";
import { ReactComponent as EditIcon } from "../../../../icons/edit.svg";
import { AssuranceActionsButtons } from "../AssuranceActionsButtons";
import { CheckboxFilter, CheckboxFilterOption } from "./CheckboxFilter";

interface PolicyMatrixToolbarProps {
    groupFilterOptions: CheckboxFilterOption[];
    methodFilterOptions: CheckboxFilterOption[];
    policiesAlertCount: number;
}

interface FilterOptionsValues {
    assuranceGroupShortNames: string[];
    assuranceMethodFullNames: string[];
}

export function PolicyMatrixToolbar({ groupFilterOptions, methodFilterOptions, policiesAlertCount }: PolicyMatrixToolbarProps) {
    const apiRef = useGridApiContext();

    const assuranceGroupShortNames: string[] = groupFilterOptions.map(option => option.value);
    const assuranceMethodFullNames: string[] = methodFilterOptions.map(option => option.value);
    const [filterOptionsValues, setFilterOptionsValues] = useState<FilterOptionsValues>({
        assuranceGroupShortNames,
        assuranceMethodFullNames,
    });

    const hideColumns = (checkedMethodFullNames: string[]): void => {
        const columnVisibilityModel: GridColumnVisibilityModel = {};

        assuranceMethodFullNames.filter(methodName => !checkedMethodFullNames.includes(methodName))
            .forEach(methodName => Object.assign(columnVisibilityModel, { [methodName]: false }));

        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        setFilterOptionsValues((prevFilterOptions) => {
            return { ...prevFilterOptions, assuranceMethodFullNames: checkedMethodFullNames };
        });
    };

    const filterRows = (checkedGroupShortNames: string[]): void => {
        apiRef.current.setFilterModel(
            { items: [{ columnField: 'Group', operatorValue: 'isGroupShortNameInArray', value: checkedGroupShortNames }] }
        );
        setFilterOptionsValues((prevFilterOptions) => {
            return { ...prevFilterOptions, assuranceGroupShortNames: checkedGroupShortNames };
        });
    };

    const clearFilters = (): void => {
        apiRef.current.setFilterModel({ items: [] });
        apiRef.current.setColumnVisibilityModel({});
        setFilterOptionsValues({
            assuranceGroupShortNames,
            assuranceMethodFullNames
        });
    };

    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'flex-start', sm: 'space-between' }} spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems='center'>
                <AssuranceActionsButtons />
                <Button
                    variant='contained'
                    size='small'
                    startIcon={<SvgIcon component={EditIcon} inheritViewBox />}
                >
                    Edit
                </Button>
                <GridToolbarExport
                    variant='outlined'
                    startIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
                />
                <CheckboxFilter
                    filterLabel='Assurance methods'
                    checkAllLabel='Show all methods'
                    allOptions={methodFilterOptions}
                    checkedOptionsValues={filterOptionsValues.assuranceMethodFullNames}
                    onChange={(checkedOptionsValues: string[]) => hideColumns(checkedOptionsValues)}
                />
                <CheckboxFilter
                    filterLabel='Assurance groups'
                    checkAllLabel='Show all groups'
                    checkedOptionsValues={filterOptionsValues.assuranceGroupShortNames}
                    allOptions={groupFilterOptions}
                    onChange={(checkedOptionsValues: string[]) => filterRows(checkedOptionsValues)}
                />
                <Typography variant='body2' color={red['A200']}>
                    Alerts on policies: {policiesAlertCount}
                </Typography>
            </Stack>
            <Button size='small' onClick={clearFilters}>
                Clear all filters
            </Button>
        </Stack>
    );
}