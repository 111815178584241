import { blue, green, orange, pink, yellow } from "@mui/material/colors";
import {
    AssuranceMethodExecutionStatusDto,
    AssuranceMethodTypeDto,
    MethodExecutionFrequencyTypeDto,
    PolicyMatrixColumnDefinitionDto, PolicyMatrixDto,
    PolicyMatrixRowDto
} from "../PolicyMatrixModel";

//TODO: should be moved to PolicyMatrixPage fixture after proper getting data from backend
const columnDefinitions: PolicyMatrixColumnDefinitionDto[] = [
    {
        methodId: 1,
        fullName: 'Supplier Audit-Critical',
        shortName: 'Sup. A. Crit.',
        methodType: AssuranceMethodTypeDto.AUDIT,
    },
    {
        methodId: 2,
        fullName: 'Service Audit-Critical',
        shortName: 'Se. A. Crit.',
        methodType: AssuranceMethodTypeDto.AUDIT,
    },
    {
        methodId: 3,
        fullName: 'Authorized Supplier Asmt.',
        shortName: 'Au. Sup. Asmt.',
        methodType: AssuranceMethodTypeDto.ASSESSMENT,
    },
    {
        methodId: 4,
        fullName: 'Low Impact Supplier Asmt.',
        shortName: 'L. Im. Sup. As.',
        methodType: AssuranceMethodTypeDto.ASSESSMENT,
    },
    {
        methodId: 5,
        fullName: 'Privacy Assessment',
        shortName: 'Priv. Asmt.',
        methodType: AssuranceMethodTypeDto.ASSESSMENT,
    },
    {
        methodId: 6,
        fullName: 'Darkbeam monitoring',
        shortName: 'D. Mon.',
        methodType: AssuranceMethodTypeDto.DARKBEAM,
    },
    {
        methodId: 7,
        fullName: 'Vendore Score monitoring',
        shortName: 'V. S. Mon.',
        methodType: AssuranceMethodTypeDto.DATA_LOOKUP,
    },
    {
        methodId: 8,
        fullName: 'Supplier Impact Assessment',
        shortName: 'Sup. Im. Asmt.',
        methodType: AssuranceMethodTypeDto.ASSESSMENT,
    },
    {
        methodId: 9,
        fullName: 'Contract Terms',
        shortName: 'Cont. terms',
        methodType: AssuranceMethodTypeDto.CONTRACT_TERMS,
    }
];

const rows: PolicyMatrixRowDto[] = [
    {
        groupId: 1,
        fullName: 'Critical IT Service',
        shortName: 'Crit. It. Se.',
        cells: [
            {
                methodName: columnDefinitions[0].fullName,
                value: {
                    executionFrequency: {
                        value: '10th d/y',
                        type: MethodExecutionFrequencyTypeDto.CONTINUOUS
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.LATE,
                }
            },
            {
                methodName: columnDefinitions[1].fullName,
                value: {
                    executionFrequency: {
                        value: '2 y.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.COMPLETED,
                }
            },
            {
                methodName: columnDefinitions[3].fullName,
                value: {
                    executionFrequency: {
                        value: '100 d.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.COMPLETED,
                }
            },
            {
                methodName: columnDefinitions[6].fullName,
                value: {
                    executionFrequency: {
                        type: MethodExecutionFrequencyTypeDto.ONCE
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.PLANNED
                }
            },
            {
                methodName: columnDefinitions[7].fullName,
                value: {
                    executionFrequency: {
                        value: '3 y.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.STARTED
                }
            },
            {
                methodName: columnDefinitions[8].fullName,
                value: {
                    tags: [
                        {
                            name: 'CITS_1',
                            color: pink[100]
                        },
                        {
                            name: 'SS_1',
                            color: green[100]
                        }
                    ]
                }
            }
        ],
    },
    {
        groupId: 2,
        fullName: 'Supporting IT Service',
        shortName: 'Sup. It. Se.',
        cells: [
            {
                methodName: columnDefinitions[2].fullName,
                value: {
                    executionFrequency: {
                        value: '1 d.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.LATE,
                }
            },
            {
                methodName: columnDefinitions[3].fullName,
                value: {
                    executionFrequency: {
                        value: '12 m.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.STARTED,
                }
            },
            {
                methodName: columnDefinitions[4].fullName,
                value: {
                    executionFrequency: {
                        value: '10th m/y',
                        type: MethodExecutionFrequencyTypeDto.CONTINUOUS
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.PLANNED,
                }
            },
            {
                methodName: columnDefinitions[8].fullName,
                value: {
                    tags: [
                        {
                            name: 'ITS_2',
                            color: blue[100]
                        },
                        {
                            name: 'DP_03',
                            color: orange[100]
                        },
                        {
                            name: 'AAA_1',
                            color: yellow[100]
                        },
                        {
                            name: 'CITS_1',
                            color: pink[100]
                        },
                        {
                            name: 'SS_1',
                            color: green[100]
                        }
                    ]
                }
            }
        ],
    },
    {
        groupId: 3,
        fullName: 'Authorised Access Rights Holder',
        shortName: 'Aut. A. Ri. Hol.',
        cells: [
            {
                methodName: columnDefinitions[8].fullName,
                value: {
                    tags: [
                        {
                            name: 'AAA_1',
                            color: yellow[100]
                        },
                    ]
                }
            }
        ],
    },
    {
        groupId: 4,
        fullName: 'Data Processor',
        shortName: 'D. Pro.',
        cells: [
            {
                methodName: columnDefinitions[0].fullName,
                value: {
                    executionFrequency: {
                        value: '3rd d/w',
                        type: MethodExecutionFrequencyTypeDto.CONTINUOUS
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.STARTED,
                }
            },
            {
                methodName: columnDefinitions[5].fullName,
                value: {
                    executionFrequency: {
                        type: MethodExecutionFrequencyTypeDto.ONCE
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.COMPLETED,
                }
            },
            {
                methodName: columnDefinitions[7].fullName,
                value: {
                    executionFrequency: {
                        value: '10th d/m',
                        type: MethodExecutionFrequencyTypeDto.CONTINUOUS
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.PLANNED,
                }
            }
        ],
    },
    {
        groupId: 5,
        fullName: 'Critical Supplier',
        shortName: 'Crit. Sup.',
    },
    {
        groupId: 6,
        fullName: 'Low Impact Supplier',
        shortName: 'L. Imp. Sup.',
        cells: [
            {
                methodName: columnDefinitions[0].fullName,
                value: {
                    executionFrequency: {
                        type: MethodExecutionFrequencyTypeDto.ONCE
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.LATE,
                }
            },
            {
                methodName: columnDefinitions[2].fullName,
                value: {
                    executionFrequency: {
                        value: '12 m.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.COMPLETED,
                }
            },
            {
                methodName: columnDefinitions[4].fullName,
                value: {
                    executionFrequency: {
                        value: '6 w.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.PLANNED,
                }
            },
            {
                methodName: columnDefinitions[5].fullName,
                value: {
                    executionFrequency: {
                        value: '1 y.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.STARTED
                }
            },
            {
                methodName: columnDefinitions[6].fullName,
                value: {
                    executionFrequency: {
                        value: '16 d.',
                        type: MethodExecutionFrequencyTypeDto.REGULAR
                    },
                    executionStatus: AssuranceMethodExecutionStatusDto.LATE
                }
            },
            {
                methodName: columnDefinitions[8].fullName,
                value: {
                    tags: [
                        {
                            name: 'CITS_1',
                            color: pink[100]
                        }
                    ]
                }
            }
        ]
    }
];

export const POLICY_MATRIX_MOCK_DATA: PolicyMatrixDto = {
    columnDefinitions: columnDefinitions,
    rows: rows,
    policiesAlertCount: 16,
};