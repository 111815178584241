import { FiberManualRecord } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { unhandledCase } from "../../../util/Common";
import { AssuranceTaskStatusDto } from "../ExecutionPlanModel";

interface AssuranceTaskStatusChipProps {
    value: AssuranceTaskStatusDto;
};

export function AssuranceTaskStatusChip(props: AssuranceTaskStatusChipProps) {
    const theme = useTheme();
    const statusColor = theme.assuranceTaskStatusColor;

    const getVariant: () => { text: string; color: string } = () => {
        switch (props.value) {
            case AssuranceTaskStatusDto.PLANNED:
                return { text: 'Planned', color: statusColor.planned };
            case AssuranceTaskStatusDto.STARTED:
                return { text: 'Started', color: statusColor.started };
            case AssuranceTaskStatusDto.COMPLETED:
                return { text: 'Completed', color: statusColor.completed };
            default:
                unhandledCase(props.value);
        }
    };
    const variant = getVariant();

    return (
        <Stack sx={{ color: variant.color }} direction='row' spacing={0.5} alignItems='center'>
            <FiberManualRecord fontSize="small" />
            <Typography sx={{ fontWeight: 100, fontSize: '1em' }}>{variant.text}</Typography>
        </Stack>
    );
}
