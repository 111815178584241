import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

export function SubmitErrorAlert() {

    const [open, setOpen] = useState<boolean>(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert
                severity="error"
                variant="filled"
                onClose={handleClose}
            >
                Backend error occurred
            </Alert>
        </Snackbar>
    );
}