import { SvgIconComponent } from "@mui/icons-material";
import { AppBar, Box, Button, IconButton, SvgIcon, Toolbar } from "@mui/material";
import { useState } from "react";
import RizikonLogo from "../../../icons/default_topnavbar_logo.png";
import { ReactComponent as DocumentsIcon } from "../../../icons/documents.svg";
import { ReactComponent as OrganisationsIcon } from "../../../icons/organisations.svg";
import { ReactComponent as ContactsIcon } from "../../../icons/contacts.svg";
import { ReactComponent as AssessmentsIcon } from "../../../icons/assessments.svg";
import { ReactComponent as SummaryIcon } from "../../../icons/summary.svg";
import { ReactComponent as NetworkIcon } from "../../../icons/network.svg";
import { ReactComponent as BellIcon } from "../../../icons/bell.svg";
import { ReactComponent as QuestionMarkIcon } from "../../../icons/questionmark.svg";
import { ReactComponent as BuildIcon } from "../../../icons/build.svg";
import { ReactComponent as ProfileIcon } from "../../../icons/profile.svg";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { TopNavBarDropdownMenu } from "./components/TopNavBarDropdownMenu";
import { MenuDef } from "./components/TopNavBarMenuDefinition";

export function TopNavBar() {
    const menus: MenuDef[] = [
        {
            text: 'Network',
            StartIcon: NetworkIcon as SvgIconComponent,
            EndIcon: ExpandMoreOutlinedIcon,
            items: [
                {
                    text: 'Organisations', Icon: OrganisationsIcon as SvgIconComponent, onClick: () => {
                    }
                },
                {
                    text: 'Contacts', Icon: ContactsIcon as SvgIconComponent, onClick: () => {
                    }
                }
            ]
        },
        {
            text: 'More', StartIcon: MoreVertOutlinedIcon, EndIcon: ExpandMoreOutlinedIcon, items: [
                {
                    text: 'Documents', Icon: DocumentsIcon as SvgIconComponent, onClick: () => {
                    }
                },
            ]
        }
    ];

    return (
        <AppBar color="default" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: '5em' }}>
            <Toolbar variant="dense" sx={{ backgroundColor: "background.paper" }}>
                <Box sx={{ height: '100%', objectFit: 'contain', padding: '0 1em 0 1em' }}>
                    <img src={RizikonLogo} alt={"Rizikon Assurance"} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "75%", justifyContent: "flex-start" }}>
                        <MenuButton Icon={SummaryIcon as SvgIconComponent} text={"Summary"} />
                        <MenuButton Icon={AssessmentsIcon as SvgIconComponent} text={"Assessments"} />
                        {menus.map((menu: MenuDef) => <TopNavBarDropdownMenu key={menu.text} {...menu} />)}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "25%", justifyContent: "flex-end" }}>
                        <MenuIconButton Icon={BellIcon as SvgIconComponent} />
                        <MenuIconButton Icon={QuestionMarkIcon as SvgIconComponent} />
                        <MenuIconButton Icon={BuildIcon as SvgIconComponent} />
                        <MenuIconButton Icon={ProfileIcon as SvgIconComponent} />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

interface MenuIconButtonProps {
    Icon: SvgIconComponent;
}

function MenuIconButton({ Icon }: MenuIconButtonProps) {
    const [buttonActive, setButtonActive] = useState<boolean>(false);

    const toggleHover = () => {
        setButtonActive(!buttonActive);
    };

    return (
        <IconButton
            sx={{ color: 'text.primary', padding: "0.5em 0.5em", height: "1.8em", margin: "auto 0" }}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}>
            <SvgIcon
                component={Icon}
                inheritViewBox
                sx={{
                    fontWeight: "300",
                    fontSize: "0.8em",
                    color: buttonActive ? "primary.main" : "text.primary"
                }}
            />
        </IconButton>
    );
}

interface MenuButtonProps {
    Icon: SvgIconComponent;
    text: string;
}

function MenuButton({ Icon, text }: MenuButtonProps) {
    const [buttonActive, setButtonActive] = useState<boolean>(false);

    const toggleHover = () => {
        setButtonActive(!buttonActive);
    };

    return (
        <Button
            startIcon={
                <SvgIcon
                    component={Icon}
                    inheritViewBox
                    sx={{ color: buttonActive ? "primary.main" : "text.primary" }}
                />
            }
            variant={"text"}
            sx={{
                color: 'text.primary',
                fontWeight: "300",
                fontSize: "1em",
                padding: "1.5em 1em",
                ":hover": { backgroundColor: "transparent" }
            }}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}>
            {text}
        </Button>
    );
}