import { AssuranceGroupInputFormInputValues } from "./AssuranceGroupInputFormModel";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, Container, Paper, Stack, Typography } from "@mui/material";
import { SubmitErrorAlert } from "../../../../components/menu/alert/SubmitErrorAlert";
import { useState } from "react";
import { MainInformationInput } from "./components/MainInformationInput";
import { GroupElementsGrid } from "./components/GroupElementsGrid";
import { AccordionWrapper } from "../../../../components/AccordionWrapper";

interface AssuranceGroupInputFormProps {
    onSubmitOperation: (formData: AssuranceGroupInputFormInputValues) => void;
    onCancelOperation: () => void; // TODO implement it
    initialData: AssuranceGroupInputFormInputValues;
}

export function AssuranceGroupInputForm(props: AssuranceGroupInputFormProps) {

    const [error] = useState(false);

    const methods = useForm<AssuranceGroupInputFormInputValues>({
        mode: "onBlur",
        defaultValues: props.initialData,
    });

    const { handleSubmit } = methods;

    const onSubmit: SubmitHandler<AssuranceGroupInputFormInputValues> = (formData: AssuranceGroupInputFormInputValues) => {
        console.log(formData);
    };

    return (
        <Container sx={{ py: 4 }}>
            {error && <SubmitErrorAlert />}
            <Paper sx={{ p: 5 }} elevation={3}>
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Stack sx={{ rowGap: 1 }}>
                            <Typography variant="caption">* required fields</Typography>
                            <AccordionWrapper summary="Main information">
                                <MainInformationInput />
                            </AccordionWrapper>
                            <AccordionWrapper summary="Group elements">
                                <GroupElementsGrid data={props.initialData.elements} />
                            </AccordionWrapper>
                            <Stack direction="row" spacing={1} >
                                <Button type="submit" variant="contained" sx={{ width: "20%" }}>Save</Button>
                                <Button variant="outlined" sx={{ width: "20%" }} onClick={props.onCancelOperation}>Cancel</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </FormProvider>
            </Paper>
        </Container>
    );
}