import {
    Button,
    Checkbox,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as AdjustIcon } from "../../../../icons/adjust.svg";

interface CheckboxFilterProps extends CheckboxGroupProps {
    filterLabel: string;
}

export function CheckboxFilter({ filterLabel, ...checkboxGroupProps }: CheckboxFilterProps) {
    const [open, setOpen] = useState<boolean>(false);
    const anchor = useRef<HTMLButtonElement | null>(null);

    const handleButtonClick = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClickAway = (): void => {
        setOpen(false);
    };

    return (
        <>
            <Button
                ref={anchor}
                size='small'
                color="secondary"
                startIcon={<SvgIcon component={AdjustIcon} inheritViewBox />}
                onClick={handleButtonClick}
                variant='outlined'
            >
                {filterLabel}
            </Button>
            <Popper
                color="secondary"
                open={open}
                anchorEl={anchor.current}
                placement='bottom-start'
                transition
                style={{ width: '30%' }}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <MenuList sx={{ px: 2, pt: 2, pb: 1.5 }}>
                                    <CheckboxGroup {...checkboxGroupProps} />
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

interface CheckboxGroupProps {
    checkAllLabel: string;
    allOptions: CheckboxFilterOption[];
    checkedOptionsValues: string[];
    onChange: (checkedOptionsValues: string[]) => void;
}

export interface CheckboxFilterOption {
    value: string;
    label?: string;
}

function CheckboxGroup({ checkAllLabel, allOptions, checkedOptionsValues, onChange }: CheckboxGroupProps) {
    const ROWS_IN_COLUMN = 6;

    const columnsCount: number = Math.ceil(allOptions.length / ROWS_IN_COLUMN);
    const breakpointsToColumnsCount: ResponsiveStyleValue<number> = {
        xs: Math.min(1, columnsCount),
        lg: Math.min(2, columnsCount)
    };

    const [checked, setChecked] = useState<string[]>(checkedOptionsValues);
    const isAllChecked: boolean = checked.length === allOptions.length;
    const [allChecked, setAllChecked] = useState<boolean>(isAllChecked);

    const handleGroupItemClick = (optionValue: string): (() => void) => {
        return (): void => {
            const optionValueIndex: number = checked.indexOf(optionValue);
            const newChecked: string[] = [...checked];

            if (optionValueIndex === -1) {
                newChecked.push(optionValue);
            } else {
                newChecked.splice(optionValueIndex, 1);
            }

            setChecked(newChecked);
            onChange(newChecked);
        };
    };

    const handleAllCheckedClick = (): void => {
        if (!allChecked) {
            const allOptionsValues = allOptions.map(option => option.value);

            setChecked(allOptionsValues);
            onChange(allOptionsValues);
        } else {
            setChecked([] as string[]);
            onChange([] as string[]);
        }

        setAllChecked(!allChecked);
    };

    useEffect(() => {
        if (isAllChecked) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
    }, [checked, isAllChecked]);

    return (
        <>
            <MenuItem sx={{ p: 0, my: 1.5, minHeight: 'fit-content' }} onClick={handleAllCheckedClick}>
                <CheckboxWithLabel label={checkAllLabel} checked={allChecked} />
            </MenuItem>
            <Divider />
            <Grid
                container
                sx={{ p: 0, mt: -1 }}
                rowSpacing={1.5}
                columnSpacing={3}
                columns={breakpointsToColumnsCount}
            >
                {allOptions.map((option) => (
                    <CheckboxGroupItem
                        key={option.value}
                        option={option}
                        checked={(checked.indexOf(option.value) > -1)}
                        onClick={handleGroupItemClick}
                    />
                ))}
            </Grid>
        </>
    );
}

interface CheckboxGroupItemProps {
    option: CheckboxFilterOption;
    checked: boolean;
    onClick: (option: string) => (() => void);
}

function CheckboxGroupItem({ option, checked, onClick }: CheckboxGroupItemProps) {
    return (
        <Grid item xs={1}>
            <MenuItem sx={{ p: 0, minHeight: 'fit-content' }} onClick={onClick(option.value)}>
                <CheckboxWithLabel
                    checked={checked}
                    label={option.label ?? option.value}
                />
            </MenuItem>
        </Grid>
    );
}

interface CheckboxWithLabelProps {
    label: string;
    checked: boolean;
}

function CheckboxWithLabel({ label, checked }: CheckboxWithLabelProps) {
    return (
        <>
            <Checkbox checked={checked} disableRipple size='small' color="secondary" />
            <Tooltip title={label}>
                <Typography variant='caption' color='text.primary' overflow='hidden'>
                    {label}
                </Typography>
            </Tooltip>
        </>
    );
}