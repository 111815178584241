import { Chip } from "@mui/material";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { from } from "../../../../../util/DataGrid";
import { AssuranceGroupElementDto, AssuranceGroupElementTagDto } from "../../../GroupsModel";

export const GroupElementsColumns: GridColumns = [
    {
        field: 'name',
        headerName: 'Name',
        valueGetter: from((it: AssuranceGroupElementDto) => it.name),
        flex: 1.5,
    },
    {
        field: 'primaryContact',
        headerName: 'Primary Contact',
        valueGetter: from((it: AssuranceGroupElementDto) => it.primaryContact),
        flex: 1.5,
    },
    {
        field: 'critality',
        headerName: 'Criticality',
        valueGetter: from((it: AssuranceGroupElementDto) => it.criticality),
    },
    {
        field: 'impact',
        headerName: 'Impact',
        valueGetter: from((it: AssuranceGroupElementDto) => it.impact),
    },
    {
        field: 'tags',
        headerName: 'Tags',
        valueGetter: from((it: AssuranceGroupElementDto) => it.tags),
        flex: 2,
        renderCell: (params: GridRenderCellParams<AssuranceGroupElementTagDto[]>) => (
            params.value?.map((tag: AssuranceGroupElementTagDto) =>
                <Chip label={tag.name} sx={{ color: "#fafafa", backgroundColor: tag.color, ml: 0.5 }} size="small" /> // TODO update styling
            )
        ),
    },
];
