import { Grid } from "@mui/material";
import { AssuranceTaskDetailsCard } from "./components/AssuranceTaskDetailsCard";
import { AssuranceTaskInfoCard } from "./components/AssuranceTaskInfoCard";
import { AssuranceTaskMethodInfoCard } from "./components/AssuranceTaskMethodInfoCard";

export function AssuranceTaskDetailsViewPage() {
    return (
        <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item md={12}>
                <AssuranceTaskInfoCard />
            </Grid>
            <Grid item xs={12} md={6}>
                <AssuranceTaskDetailsCard />
            </Grid>
            <Grid item xs={12} md={6}>
                <AssuranceTaskMethodInfoCard />
            </Grid>
        </Grid>
    );
};
