import { Chip } from "@mui/material";

interface TagProps {
    name: string;
    color: string;
}

export function Tag({ name, color }: TagProps) {
    return (
        <Chip size='small' sx={{ bgcolor: color }} label={name} />
    );
}