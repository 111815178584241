import { Box, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValid } from "date-fns";
import { useController, UseControllerProps, useFormContext } from "react-hook-form";
import { CheckboxGroup, SingleCheckbox } from "../../../../../components/CheckboxGroup";
import { unhandledCase } from "../../../../../util/Common";
import { AssuranceMethodExecutionFrequencyDto } from "../../../AssuranceMethodsModel";
import { AssuranceMethodInputValues, Duration, Frequency } from "../AssuranceMethodInputFormModel";
import { DurationInput } from "./DurationInput";
import { FrequencyInput } from "./FrequencyInput";

type AllowedExecutionDatePickerNames = "executionStartDate" | "executionEndDate";

interface ExecutionDatePickerProps
    extends Pick<UseControllerProps<AssuranceMethodInputValues, AllowedExecutionDatePickerNames>, "name"> {
    label: string;
    helperText: string;
}

function ExecutionDatePicker({ name, label, helperText }: ExecutionDatePickerProps) {

    const { control } = useFormContext<AssuranceMethodInputValues>();

    const { field: { onBlur, ...rest }, fieldState: { error } } = useController({
        name: name,
        control: control,
        rules: { validate: { validDate: date => date == null || isValid(date) } },
    });

    return (
        <DatePicker
            {...rest}
            label={label}
            disableMaskedInput
            inputFormat="dd MMMM yyyy"
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="standard"
                    error={!!error}
                    onBlur={onBlur}
                    helperText={error ? "Invalid date" : helperText}
                />
            }
        />
    );
}

function ExecutionFrequencyInput() {

    const { control, getValues } = useFormContext<AssuranceMethodInputValues>();

    const { field: { onChange } } = useController({
        name: "executionFrequency",
        control: control,
    });

    function getFrequencyLabel(frequency: AssuranceMethodExecutionFrequencyDto) {
        switch (frequency) {
            case AssuranceMethodExecutionFrequencyDto.ONCE_ONLY:
                return "Once only";
            case AssuranceMethodExecutionFrequencyDto.CONTINUOUS:
                return "Continuous";
            case AssuranceMethodExecutionFrequencyDto.PERIODIC:
                return "Periodic";
            case AssuranceMethodExecutionFrequencyDto.PER_CONTRACT:
                return "Per contract";
            default:
                unhandledCase(frequency);
        }
    }

    const frequencies: SingleCheckbox<AssuranceMethodExecutionFrequencyDto>[] = Object.values(AssuranceMethodExecutionFrequencyDto)
        .map((frequency: AssuranceMethodExecutionFrequencyDto) => ({
            value: frequency,
            label: getFrequencyLabel(frequency),
            size: "small",
            checked: getValues("executionFrequency").includes(frequency),
            xs: 12,
            sm: 3,
            md: 2,
        }) as SingleCheckbox<AssuranceMethodExecutionFrequencyDto>);

    return (
        <Box>
            <Typography sx={{ mb: 2 }}>Execution Frequency options</Typography>

            <CheckboxGroup<AssuranceMethodExecutionFrequencyDto>
                checkboxes={frequencies}
                onChange={(newState: AssuranceMethodExecutionFrequencyDto[]) => onChange(newState)}
            />
        </Box>
    );
}

function StartTimeInput() {

    const { control, getValues } = useFormContext<AssuranceMethodInputValues>();

    const name = "startTimeBeforeDueDate";

    const { field: { onChange, onBlur }, fieldState: { error } } = useController({
        name: name,
        control: control,
        rules: { required: "Lead time is required" },
    });

    return (
        <Box>
            <Typography sx={{ mb: 2 }}>How long before the due date should the method be started</Typography>

            <DurationInput
                required
                id={name}
                initDuration={getValues(name)}
                onChange={(newState: Duration | null) => onChange(newState)}
                onBlur={onBlur}
                label="Lead time"
                error={!!error}
                helperText={error ? error.message : "Lead time"}
            />
        </Box>
    );
}

function MaxExecutionsNumberInput() {

    const { control, getValues } = useFormContext<AssuranceMethodInputValues>();

    const name = "maxExecutionsNumber";

    const { field: { onChange } } = useController({
        name: name,
        control: control,
    });

    return (
        <Box>
            <Typography sx={{ mb: 2 }}>Maximum number of method executions in a given time period</Typography>

            <FrequencyInput
                id={name}
                initFrequency={getValues(name)}
                onChange={(newState: Frequency | null) => onChange(newState)}
                label="Maximum rate"
                helperText="Method rate value"
            />
        </Box>
    );
}

export function ExecutionInput() {
    return (
        <Stack sx={{ rowGap: 4 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 4 }}
                    sx={{ alignItems: { sm: "center" } }}
                >
                    <ExecutionDatePicker
                        name="executionStartDate"
                        label="Execution start date"
                        helperText="Method cannot start before this date"
                    />
                    <Typography sx={{ alignSelf: { xs: "center" } }}>-</Typography>
                    <ExecutionDatePicker
                        name="executionEndDate"
                        label="Execution end date"
                        helperText="Method cannot start after this date"
                    />
                </Stack>
            </LocalizationProvider>
            <ExecutionFrequencyInput />
            <StartTimeInput />
            <MaxExecutionsNumberInput />
        </Stack>
    );
}