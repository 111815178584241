import { Link as MuiLink, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface AssuranceMethodNameProps {
    methodId: number;
    name: string;
    tooltipTitle?: string;
}

export function AssuranceMethodNameLink({ methodId, name, tooltipTitle }: AssuranceMethodNameProps) {
    return (
        <Tooltip title={tooltipTitle}>
            <MuiLink component={RouterLink} to={"/assurancemethods/edit"}>
                {name}
            </MuiLink>
        </Tooltip>
    );
}