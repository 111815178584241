import { Grid } from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ReactNode } from "react";
import { InfoCard } from "../../../../../components/infoCard/InfoCard";
import { InfoCardItem } from "../../../../../components/infoCard/InfoCardItem";
import { InfoCardTextItem } from "../../../../../components/infoCard/InfoCardTextItem";
import { AssuranceTaskStatusChip } from "../../../components/AssuranceTaskStatusChip";
import { AssuranceTaskStatusDto } from "../../../ExecutionPlanModel";

interface GridItemProps {
    children: ReactNode;
}

function GridItem({ children }: GridItemProps) {
    return (
        <Grid item xs={12} md={4}>
            {children}
        </Grid>
    );
};

export function AssuranceTaskInfoCard() {
    return (
        <InfoCard title="Assurance task info" description="Main information about assurance task.">
            <Grid container>
                <GridItem>
                    <InfoCardItem label="Assignee">
                        <MuiLink component={RouterLink} to={"/"}>Giorno Giovani</MuiLink>
                    </InfoCardItem>
                </GridItem>
                <GridItem>
                    <InfoCardItem label="Status">
                        <AssuranceTaskStatusChip value={AssuranceTaskStatusDto.PLANNED} />
                    </InfoCardItem>
                </GridItem>
                <GridItem>
                    <InfoCardTextItem label="Assessment" text="" />
                </GridItem>
                <GridItem>
                    <InfoCardItem label="Method">
                        <MuiLink component={RouterLink} to={"/"}>Service Audit - Critical</MuiLink>
                    </InfoCardItem>
                </GridItem>
                <GridItem>
                    <InfoCardItem label="Assurance method category">
                        <MuiLink component={RouterLink} to={"/"}>Supplier</MuiLink>
                    </InfoCardItem>
                </GridItem>
                <GridItem>
                    <InfoCardTextItem label="Type" text="Giorno Manual" />
                </GridItem>
                <GridItem>
                    <InfoCardItem label="Target Name">
                        <MuiLink component={RouterLink} to={"/"}>Flying Tween Systems</MuiLink>
                    </InfoCardItem>
                </GridItem>
                <GridItem>
                    <InfoCardItem label="Target Group">
                        <MuiLink component={RouterLink} to={"/"}>Very Low Impact Supplier</MuiLink>
                    </InfoCardItem>
                </GridItem>
                <GridItem>
                    <InfoCardTextItem label="Owner note" text="Any other useful information about them and how to contact them." />
                </GridItem>
            </Grid>
        </InfoCard>
    );
}
