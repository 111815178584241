import { SvgIconComponent } from "@mui/icons-material";
import { Button, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, SvgIcon } from "@mui/material";
import { useRef, useState } from "react";
import { theme } from "../../../../root/theme";

interface DropdownMenuProps {
    text: string;
    StartIcon: SvgIconComponent;
    EndIcon: SvgIconComponent;
    items: Array<DropdownMenuItemProps>;
}

export function TopNavBarDropdownMenu({ text, StartIcon, EndIcon, items }: DropdownMenuProps) {
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLButtonElement | null>(null);
    const [buttonActive, setButtonActive] = useState<boolean>(false);

    const toggleHover = () => {
        setButtonActive(!buttonActive);
    };
    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const decorateWithHandleClose = (func: () => void) => {
        return function () {
            func();
            handleClose();
        };
    };

    return (
        <>
            <Button ref={anchor}
                    variant='text'
                    sx={{
                        ":hover": { backgroundColor: "transparent" },
                        color: theme.palette.text.primary,
                        fontWeight: "300",
                        fontSize: "1em",
                        padding: "1.5em 1em"
                    }}
                    startIcon={
                        <SvgIcon
                            component={StartIcon}
                            inheritViewBox
                            sx={{ color: buttonActive ? "primary.main" : "text.primary" }}
                        />
                    }
                    endIcon={<EndIcon />}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}
                    onClick={handleClick}>
                {text}
            </Button>
            <Popper
                open={open}
                anchorEl={anchor.current}
                placement='bottom-start'
                sx={{ zIndex: 10000 }}
                transition
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                        <Paper sx={{ minWidth: 150 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList sx={{ py: 0 }}>
                                    {items.map((item: DropdownMenuItemProps) =>
                                        <DropdownMenuItem key={item.text} {...item} onClick={decorateWithHandleClose(item.onClick)} />
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

interface DropdownMenuItemProps {
    text: string;
    Icon: SvgIconComponent;
    color?: string;
    onClick: () => void;
}

function DropdownMenuItem({ text, Icon, color, onClick }: DropdownMenuItemProps) {
    return (
        <MenuItem onClick={onClick} sx={{ p: 1 }}>
            <ListItemIcon sx={{ minWidth: 28 }}>
                <SvgIcon component={Icon} inheritViewBox sx={{ color: "primary.main", fontSize: "1em" }} />
            </ListItemIcon>
            <ListItemText primary={text} primaryTypographyProps={{ fontSize: '0.875rem', color: color }} />
        </MenuItem>
    );
}