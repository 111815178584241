import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { unhandledCase } from "../../../util/Common";
import {
    AssuranceMethodExecutionStatusDto,
    MethodExecutionFrequencyDto,
    MethodExecutionFrequencyTypeDto
} from "../PolicyMatrixModel";
import { PolicyAlertsDialog } from "./PolicyAlertsDialog";

interface ExecutionFrequencyCellProps {
    assuranceMethodId: number;
    assuranceGroupId: number;
    executionFrequency: MethodExecutionFrequencyDto;
    executionStatus: AssuranceMethodExecutionStatusDto;
}

function getCellLabel({ value, type }: MethodExecutionFrequencyDto): string {
    switch (type) {
        case MethodExecutionFrequencyTypeDto.REGULAR:
        case MethodExecutionFrequencyTypeDto.CONTINUOUS:
            if (value === undefined) {
                throw new Error(`Undefined MethodExecutionFrequencyDto's value property for type: ${type}`);
            }
            return value;
        case MethodExecutionFrequencyTypeDto.ONCE:
            return 'Once';
        default:
            unhandledCase(type);
    }
}

export function ExecutionFrequencyCell(
    { assuranceMethodId, assuranceGroupId, executionFrequency, executionStatus }: ExecutionFrequencyCellProps
) {
    const theme = useTheme();
    const methodExecutionStatusColor = theme.assuranceMethodExecutionStatusColor;

    const [openPolicyAlertsDialog, setOpenPolicyAlertsDialog] = useState<boolean>(false);
    const handleCellClick = (): void => {
        setOpenPolicyAlertsDialog(true);
    };
    const handleDialogClose = (): void => {
        setOpenPolicyAlertsDialog(false);
    };

    return (
        <>
            <Box onClick={handleCellClick} sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                {getCellLabel(executionFrequency)}
                {executionStatus === AssuranceMethodExecutionStatusDto.LATE &&
                    <Box
                        sx={{
                            bgcolor: methodExecutionStatusColor.late,
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            borderRadius: '0 0 0 0.7rem',
                            height: '0.75rem',
                            width: '0.75rem'
                        }}
                    />
                }
            </Box>
            <PolicyAlertsDialog
                assuranceMethodId={assuranceMethodId}
                assuranceGroupId={assuranceGroupId}
                open={openPolicyAlertsDialog}
                onClose={handleDialogClose}
            />
        </>
    );
}