import ClearIcon from "@mui/icons-material/Clear";
import {
    BaseTextFieldProps,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import * as React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Duration, Period } from "../AssuranceMethodInputFormModel";

interface DurationInputProps extends Pick<BaseTextFieldProps, "onBlur"> {
    id: string;
    initDuration: Duration | null;
    onChange: (state: Duration | null) => void;
    label: string;
    helperText?: string;
    required?: boolean;
    error?: boolean;
}

export function DurationInput(props: DurationInputProps) {

    const {
        id,
        initDuration,
        onChange,
        onBlur,
        label,
        helperText,
        required,
        error,
    }: DurationInputProps = props;

    const [duration, setDuration] = useState<Duration | null>(initDuration);

    const onChangeCallback = useRef<(state: Duration | null) => void>(onChange);

    useEffect(() => {
        onChangeCallback.current(
            duration?.rate && duration?.unit
                ? { rate: duration.rate, unit: duration.unit } as Duration
                : null
        );
    }, [duration?.rate, duration?.unit, onChangeCallback]);

    function clearRate() {
        setDuration((prevDuration: Duration | null) => {
            return { ...prevDuration, rate: "" } as Duration;
        });
    }

    function updateRate(newRate: string) {
        setDuration((prevDuration: Duration | null) => {
            return { ...prevDuration, rate: newRate } as Duration;
        });
    }

    function updateUnit(newUnit: Period) {
        setDuration((prevDuration: Duration | null) => {
            return { ...prevDuration, unit: newUnit } as Duration;
        });
    }

    return (
        <Grid container columnSpacing={2} sx={{ alignItems: { sm: "center" } }}>
            <Grid item xs={12} sm={3} md={2}>
                <TextField
                    required={required}
                    id={`${id}-rate`}
                    value={duration?.rate ?? ""}
                    variant="standard"
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    error={error}
                    helperText={helperText}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => updateRate(event.target.value)}
                    onBlur={onBlur}
                    InputProps={{
                        endAdornment:
                            <IconButton
                                onClick={clearRate}
                                sx={{ visibility: duration?.rate ? "visible" : "hidden" }}
                            >
                                <ClearIcon sx={{ fontSize: "medium" }} />
                            </IconButton>
                    }}
                    sx={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={12} sm={1} md={0.5} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>-</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <FormControl
                    required={required}
                    variant="standard"
                    sx={{ width: "100%" }}
                >
                    <InputLabel id={`${id}-unitLabel`}>Unit</InputLabel>
                    <Select
                        id={`${id}-unit`}
                        value={duration?.unit ?? "" as Period}
                        labelId={`${id}-unitLabel`}
                        label="Unit"
                        disabled={!duration?.rate}
                        onChange={(event: SelectChangeEvent<Period>) => updateUnit(event.target.value as Period)}
                    >
                        {Object.values(Period).map((period: Period) =>
                            <MenuItem key={period} value={period}>{period}</MenuItem>
                        )}
                    </Select>
                    <FormHelperText>Time</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
}